import {
  animationVariants,
  FixedServiceRequestTypes,
} from "../core/constants/constants";
import { IConfirmation } from "../core/types/types";
import { useNavigate } from "react-router";
import { motion } from "framer-motion";
import { useEffect } from "react";

function Confirmation ({
  hasCompletedForm,
  serviceRequest,
} : IConfirmation) {
  const navigate = useNavigate();

  // Prevent manual navigation to page
  useEffect(() => {
    if (!hasCompletedForm) {
      navigate("/");
    }
  }, [navigate, hasCompletedForm]);

  // Scroll to top when component mounts
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <motion.div
      variants={animationVariants}
      initial="initial"
      animate="in"
      exit="out"
      className="sm:mt-10 mb-10 w-11/12 md:w-8/12"
    >
      <div className="py-12 my-auto mx-auto flex flex-col items-start justify-start xl:w-4/5 2xl:w-6/12">
        <motion.h1
          variants={animationVariants}
          className="heading-font mb-6 xl:mb-10 w-full text-left"
        >
          Thank you
        </motion.h1>
        {serviceRequest.serviceRequest !==
          FixedServiceRequestTypes.ADDITIONAL_BIN &&
          serviceRequest.serviceRequest !==
            FixedServiceRequestTypes.GARDEN_WASTE_NEW &&
          serviceRequest.serviceRequest !==
            FixedServiceRequestTypes.GARDEN_WASTE_CHANGE &&
          serviceRequest.serviceRequest !==
            FixedServiceRequestTypes.CHANGE_BIN_SIZES && (
            <>
              <motion.p
                variants={animationVariants}
                className="mb-4 confirmation-font"
              >
                We will attend to this matter as soon as possible and will
                contact you if required. If this matter is urgent, please phone
                the number at the bottom of this page.
              </motion.p>
              <motion.p
                variants={animationVariants}
                className="mb-4 confirmation-font"
              >
                {`Your service request number is #${serviceRequest.ticketNumber}.`}
              </motion.p>
              <motion.p
                variants={animationVariants}
                className="mb-4 confirmation-font"
              >
                Please keep this number as a reference for all enquiries related
                to this matter.
              </motion.p>
              <motion.p
                variants={animationVariants}
                className="mb-10 xl:mb-14 confirmation-font"
              >
                Note: If you have requested an additional bin or signed up for
                the garden waste service, we will contact you shortly to
                organise an invoice for this additional service to the address
                you have provided.
              </motion.p>
            </>
          )}
        {serviceRequest.serviceRequest ===
          FixedServiceRequestTypes.ADDITIONAL_BIN && (
          <>
            <motion.p
              variants={animationVariants}
              className="mb-4 confirmation-font"
            >
              Thanks, we have received your request
            </motion.p>
            <motion.p
              variants={animationVariants}
              className="mb-4 confirmation-font"
            >
              {`Your service request number is #${serviceRequest.ticketNumber}.`}
            </motion.p>
            <motion.p
              variants={animationVariants}
              className="mb-4 confirmation-font"
            >
              If your request is approved, we will aim to deliver your
              additional bin/s within ten working days of approval.
            </motion.p>
            <motion.p
              variants={animationVariants}
              className="mb-10 confirmation-font"
            >
              A confirmation email will be sent with more information. This will
              be sent to the email address provided when you requested the
              additional bin/s.
            </motion.p>
          </>
        )}
        {serviceRequest.serviceRequest ===
          FixedServiceRequestTypes.GARDEN_WASTE_NEW && (
          <>
            <motion.p
              variants={animationVariants}
              className="mb-4 confirmation-font"
            >
              Thanks, you’re all signed up for the garden waste service.
            </motion.p>
            <motion.p
              variants={animationVariants}
              className="mb-4 confirmation-font"
            >
              {`Your service request number is #${serviceRequest.ticketNumber}.`}
            </motion.p>
            <motion.p
              variants={animationVariants}
              className="mb-4 confirmation-font"
            >
              We aim to deliver your garden waste bin within ten working days.
            </motion.p>
            <motion.p
              variants={animationVariants}
              className="mb-10 confirmation-font"
            >
              Inside your garden waste bin will be an information brochure,
              which includes a collection calendar. Check your calendar to find
              out when your first garden waste collection will take place.
            </motion.p>
            <motion.p
              variants={animationVariants}
              className="mb-10 confirmation-font"
            >
              A confirmation email will be sent with more information. This will
              be sent to the email address provided when you signed up for the
              service.
            </motion.p>
          </>
        )}
        {serviceRequest.serviceRequest ===
          FixedServiceRequestTypes.GARDEN_WASTE_CHANGE && (
          <>
            <motion.p
              variants={animationVariants}
              className="mb-4 confirmation-font"
            >
              Thanks, your change in garden waste collection frequency has been
              approved.
            </motion.p>
            <motion.p
              variants={animationVariants}
              className="mb-4 confirmation-font"
            >
              {`Your service request number is #${serviceRequest.ticketNumber}.`}
            </motion.p>
            <motion.p
              variants={animationVariants}
              className="mb-4 confirmation-font"
            >
              Please write your new collection frequency (4W or 2W) on the
              sticker on the side of your bin.
            </motion.p>
            <motion.p
              variants={animationVariants}
              className="mb-10 confirmation-font"
            >
              A confirmation email will be sent with more information. This will
              be sent to the email address provided when you signed up to the
              service.
            </motion.p>
          </>
        )}
        {serviceRequest.serviceRequest ===
          FixedServiceRequestTypes.CHANGE_BIN_SIZES && (
          <>
            <motion.p
              variants={animationVariants}
              className="mb-4 confirmation-font"
            >
              Thanks, your request to change the size of your bin/s has been
              submitted.
            </motion.p>
            <motion.p
              variants={animationVariants}
              className="mb-4 confirmation-font"
            >
              {`Your service request number is #${serviceRequest.ticketNumber}.`}
            </motion.p>
            <motion.p
                variants={animationVariants}
                className="mb-4 confirmation-font"
            >
              Make sure you keep this number as a reference for all enquiries related to this matter.
            </motion.p>
            <motion.p
              variants={animationVariants}
              className="mb-4 confirmation-font"
            >
              A confirmation email will be sent with more information.
            </motion.p>
            <motion.p
                variants={animationVariants}
                className="mb-10 confirmation-font"
            >
              If you need to speak to us urgently, please call +64 7 577 7000
            </motion.p>
          </>
        )}
        <motion.div
          variants={animationVariants}
          className="mx-auto sm:mx-0 grid gap-4 sm:grid-cols-2 overflow-visible"
        >
          <a
            href="/"
            className="body-font text-center text-white bg-tcc-green transition duration-300 ease-in-out hover:bg-tcc-green-lighter rounded px-4 py-2.5 md:py-4 focus:outline-none border border-transparent focus:border-gray-800"
          >
            Request another service
          </a>
          <a
            href="https://www.tauranga.govt.nz/"
            className="body-font text-center text-white bg-tcc-green transition duration-300 ease-in-out hover:bg-tcc-green-lighter rounded px-4 py-2.5 md:py-4 focus:outline-none border border-transparent focus:border-gray-800"
          >
            Rubbish & recycling homepage
          </a>
        </motion.div>
      </div>
    </motion.div>
  );
};

export default Confirmation;
