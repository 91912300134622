const local = {
  requireLogin: false,
  apiBaseUrl: "http://localhost:7071/API/",
  keys: {
    createServiceRequestKey: "",
    checkAddressKey: "",
  },
};

const dev = {
  requireLogin: true,
  apiBaseUrl: "https://environz-api-dev.azurewebsites.net/API/",
  keys: {
    createServiceRequestKey: "wtxg8KH0Hy82VrO3SJyJ2oXSSZNAisYb5gL2hin1NuMHlRniAywYFA==",
    checkAddressKey: "ovF57taafv4Lg0MRzupyAv8umU9HEc0EQTf8PgcXAyGideLUvm90uQ==",
  },
};

const prod = {
  requireLogin: false,
  apiBaseUrl: "https://environz-api.azurewebsites.net/API/",
  keys: {
    createServiceRequestKey: "q/azc6BnIkmpIGpdVlR9CCB9/j6JJfwlLkC9M2/7W2NR1pkOIrfA0Q==",
    checkAddressKey: "iq61QUjXpKNDpSX57gs5rTALx6mJRxKLZpemWuIVViSCpl4noDC2YA==",
  },
};

export const envConfig =
  process.env.REACT_APP_ENVIRONMENT === "prod"
    ? prod
    : process.env.REACT_APP_ENVIRONMENT === "local"
    ? local
    : dev;
