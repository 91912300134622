import DevLogin from "./components/DevLogin/DevLogin";
import Footer from "./components/Footer/Footer";
import Confirmation from "./pages/Confirmation";
import NotFound from "./pages/NotFound";
import Nav from "./components/Nav/Nav";
import Home from "./pages/Home";
import { Route, Routes, useLocation } from "react-router";
import { IServiceRequest } from "./core/types/types";
import { AnimatePresence } from "framer-motion";
import { useEffect, useMemo, useState } from "react";
import "./styles/styles.css";
import { envConfig } from "./core/environment/envConfig";
import { FixedServiceRequestTypes } from "./core/constants/constants";

function App() {
  // For dev login
  const [isLoggedIn, updateLogIn] = useState(false);

  // For router
  const location = useLocation();

  // Initial state for the main service request
  const initialState: IServiceRequest = useMemo(
    () => ({
      ticketNumber: 0,
      serviceRequest: null,
      service: null,
      comments: null,
      addressComponents: {
        FormattedAddress: null,
        StreetNumber: null,
        RoadName: null,
        SuburbName: null,
        CityName: null,
        PostCode: null,
        RegionName: null,
        Latitude: null,
        Longitude: null,
      },
      name: null,
      emailAddress: null,
      phoneNo: null,
      assessmentNumber: null,
      customerNumber: null,
      wasteDetails: {
        wasteDescription: null,
        approxAmount: null,
        duration: null,
        whoDumped: null,
        occurredPreviously: null,
        hazardousItems: null,
        didWitness: null,
      },
      missedCollectionDetails: {
        isMUD: null,
        otherBinsMissed: null,
      },
      publicLitterType: null,
      looseLitterType: null,
      gardenWasteFrequency: null,
      newBinBundle: null,
    }),
    []
  );

  // State for the main service request
  const [serviceRequest, updateServiceRequest] = useState(initialState);

  // State for page navigation management
  const [hasCompletedForm, updateHasCompletedForm] = useState(false);

  // Update the initial state if the url contains garden-waste
  useEffect(() => {
    if (location.pathname.includes("garden-waste")) {
      updateServiceRequest({
        ...initialState,
        serviceRequest: FixedServiceRequestTypes.GARDEN_WASTE_NEW,
      });
    } else if (location.pathname.includes("change-bin-size")) {
      updateServiceRequest({
        ...initialState,
        serviceRequest: FixedServiceRequestTypes.CHANGE_BIN_SIZES,
      });
    }
  }, [initialState, location.pathname]);

  return envConfig.requireLogin && !isLoggedIn ? (
    <DevLogin updateLogin={updateLogIn} />
  ) : (
    <div className="overflow-x-hidden">
      <div className="min-h-screen bg-tcc-blue bg-tcc-background bg-fixed bg-center w-full flex flex-col items-center justify-start relative">
        <Nav />
        <div className="bg-tcc-grey h-10 w-full"></div>
        <AnimatePresence>
          <Routes location={location} key={location.key}>
            <Route path="/" element={<Home
                serviceRequest={serviceRequest}
                updateServiceRequest={updateServiceRequest}
                initialState={initialState}
                hasCompletedForm={hasCompletedForm}
                updateHasCompletedForm={updateHasCompletedForm}
                />}
            />
            <Route path="/garden-waste" element={<Home
                serviceRequest={serviceRequest}
                updateServiceRequest={updateServiceRequest}
                initialState={initialState}
                hasCompletedForm={hasCompletedForm}
                updateHasCompletedForm={updateHasCompletedForm}
                />}
            />
            <Route path="/change-bin-size" element={<Home
                serviceRequest={serviceRequest}
                updateServiceRequest={updateServiceRequest}
                initialState={initialState}
                hasCompletedForm={hasCompletedForm}
                updateHasCompletedForm={updateHasCompletedForm}
                />}
            />
            <Route path="/confirmation"
               key="confirmation-page"
               element={<Confirmation
                 hasCompletedForm={hasCompletedForm}
                 serviceRequest={serviceRequest}
                 />}
            />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </AnimatePresence>
        <div className="bg-tcc-blue h-10 w-full self-stretch absolute bottom-0">
          <div className="bg-tcc-blue transform -translate-x-1/2 rotate-45 h-10 w-10 absolute left-1/2 -bottom-1/2"></div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default App;
