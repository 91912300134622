import { FormEvent, SyntheticEvent, useState } from "react";
import AddressAutocomplete from "../../components/AddressAutocomplete/AddressAutocomplete";
import ExclamationMark from "../../components/ExclamationMark/ExclamationMark";
import StyledCheckboxes from "../../components/StyledInputs/StyledCheckboxes";
import StyledTextArea from "../../components/StyledInputs/StyledTextArea";
import ConfirmationModal from "../../components/Modals/ConfirmationModal";
import QuestionMark from "../../components/QuestionMark/QuestionMark";
import StyledRadio from "../../components/StyledInputs/StyledRadio";
import StyledInput from "../../components/StyledInputs/StyledInput";
import DotSpinner from "../../components/DotSpinner/DotSpinner";
import HelperModal from "../../components/Modals/HelperModal";
import { IStandardServicePath } from "../../core/types/types";
import { helpers } from "../../core/services/helpers";
import { motion } from "framer-motion";
import {
  animationVariants,
  FixedServiceRequestTypes,
  FixedServiceTypes,
} from "../../core/constants/constants";

function StandardServicePath({
  serviceRequest,
  updateServiceRequest,
  onSubmit,
  checkboxErrorMessage,
  updateCheckboxErrorMessage,
  isLoading,
  formErrorMessage,
  assessmentNoError,
  updateAssessmentNoError,
  customerNoError,
  updateCustomerNoError,
  backupStreetNo,
  backupStreetName,
  updateBackupStreetNo,
  updateBackupStreetName,
  updateBackupPostCode,
  manualAddress,
  updateManualAddress,
  isConfirmationModalOpen,
  setIsConfirmationModalOpen,
}: IStandardServicePath) {
  const [addressErrorMessage, updateAddressErrorMessage] = useState<
    string | null
  >(null);
  const [isValidAddress, updateIsValidAddress] = useState(false);
  const [onCollectionTime, updateOnCollectionTime] = useState<boolean | null>(
    null
  );
  const [hasContaminatedSticker, updateHasContaminatedSticker] = useState<
    boolean | null
  >(null);
  const [isCorrectWeek, updateIsCorrectWeek] = useState<boolean | null>(null);
  const [isHelperModalOpen, setIsHelperModalOpen] = useState<boolean>(false);

  // Update service list in service request state when a checkbox is ticked or unticked
  function handleCheckboxChange(e: SyntheticEvent) {
    updateCheckboxErrorMessage(null);
    const value = (e.target as HTMLInputElement).value;

    if (serviceRequest.service === null) {
      updateServiceRequest({
        ...serviceRequest,
        service: [value],
      });
    } else {
      if (!serviceRequest.service?.includes(value)) {
        updateServiceRequest({
          ...serviceRequest,
          service: [...serviceRequest.service, value],
        });
      } else {
        updateServiceRequest({
          ...serviceRequest,
          service: serviceRequest.service.filter((item) => item !== value),
        });
      }
    }
  }

  return (
    <motion.form
      variants={animationVariants}
      initial="initial"
      animate="in"
      exit="out"
      className="flex flex-col items-center w-full"
      onSubmit={(e: FormEvent) => {
        e.preventDefault();
        // prevents double submission
        if (!isLoading) {
          onSubmit(false, false);
        }
      }}>
      {serviceRequest.serviceRequest !==
        FixedServiceRequestTypes.GARDEN_WASTE_NEW &&
      serviceRequest.serviceRequest !==
        FixedServiceRequestTypes.GARDEN_WASTE_CHANGE &&
      serviceRequest.serviceRequest !==
        FixedServiceRequestTypes.CHANGE_BIN_SIZES &&
      serviceRequest.serviceRequest !==
        FixedServiceRequestTypes.CANCEL_GARDEN_WASTE &&
      serviceRequest.serviceRequest !==
        FixedServiceRequestTypes.NEW_PROPERTY_BIN ? (
        <motion.div variants={animationVariants} className="w-full">
          <StyledCheckboxes
            serviceRequest={serviceRequest}
            label={
              serviceRequest.serviceRequest ===
              FixedServiceRequestTypes.ADDITIONAL_BIN
                ? "Select the services that relate to your request. Please note that you must be a ratepayer to request any additional bins."
                : "Select the services that relate to your request."
            }
            onChange={(e) => {
              handleCheckboxChange(e);
            }}
            serviceList={
              serviceRequest.serviceRequest ===
              FixedServiceRequestTypes.ADDITIONAL_BIN
                ? FixedServiceTypes.additionalBinServiceList
                : FixedServiceTypes.serviceList
            }
            checkboxErrorMessage={checkboxErrorMessage}
          />
        </motion.div>
      ) : null}
      {serviceRequest.serviceRequest ===
        FixedServiceRequestTypes.GARDEN_WASTE_NEW ||
      serviceRequest.serviceRequest ===
        FixedServiceRequestTypes.GARDEN_WASTE_CHANGE ||
      serviceRequest.serviceRequest ===
        FixedServiceRequestTypes.CANCEL_GARDEN_WASTE ? (
        <motion.div variants={animationVariants} className="w-full">
          <div className="flex flex-col items-start w-full mb-4 bg-white rounded px-4 py-4 border border-gray-300 shadow-sm">
            <label
              htmlFor="greenWasteFrequency"
              className="text-base text-gray-500 pb-4">
              What would you like to do?
            </label>
            <div className="flex flex-col w-full">
              <div
                className="grid grid-cols-1 sm:grid-cols-2 sm:gap-0 gap-2 w-full"
                onChange={(e: SyntheticEvent) => {
                  serviceRequest.serviceRequest = (
                    e.target as HTMLInputElement
                  ).value;
                  serviceRequest.service = [FixedServiceTypes.GARDEN_WASTE];
                  updateServiceRequest({
                    ...serviceRequest,
                  });
                }}>
                <label
                  htmlFor={FixedServiceRequestTypes.GARDEN_WASTE_NEW}
                  className="flex flex-row items-center w-full cursor-pointer">
                  <input
                    id={FixedServiceRequestTypes.GARDEN_WASTE_NEW}
                    name="greenWasteService"
                    type="radio"
                    value={FixedServiceRequestTypes.GARDEN_WASTE_NEW}
                    className="mr-2 cursor-pointer"
                    required={true}
                  />
                  Sign up to a new service
                </label>
                <label
                  htmlFor={FixedServiceRequestTypes.GARDEN_WASTE_CHANGE}
                  className="flex flex-row items-center w-full cursor-pointer">
                  <input
                    id={FixedServiceRequestTypes.GARDEN_WASTE_CHANGE}
                    name="greenWasteService"
                    type="radio"
                    value={FixedServiceRequestTypes.GARDEN_WASTE_CHANGE}
                    className="mr-2 cursor-pointer"
                  />
                  Change your current frequency
                </label>
                <label
                  htmlFor={FixedServiceRequestTypes.CANCEL_GARDEN_WASTE}
                  className="flex flex-row items-center w-full cursor-pointer">
                  <input
                    id={FixedServiceRequestTypes.CANCEL_GARDEN_WASTE}
                    name="greenWasteService"
                    type="radio"
                    value={FixedServiceRequestTypes.CANCEL_GARDEN_WASTE}
                    className="mr-2 cursor-pointer"
                  />
                  Cancel garden waste service
                </label>
              </div>
            </div>
          </div>
        </motion.div>
      ) : null}
      {serviceRequest.serviceRequest ===
        FixedServiceRequestTypes.CHANGE_BIN_SIZES ||
      serviceRequest.serviceRequest ===
        FixedServiceRequestTypes.NEW_PROPERTY_BIN ? (
        <motion.div variants={animationVariants} className="w-full">
          <div className="flex flex-col items-start w-full mb-4 bg-white rounded px-4 py-4 border border-gray-300 shadow-sm">
            <label
              htmlFor="ChangeBinSizes"
              className="text-base text-gray-500 pb-4">
              Select the bin bundle you would like: (single select option)
            </label>
            <div className="flex flex-col w-full">
              <div
                className="grid grid-cols-1 w-full gap-2"
                onChange={(e) => {
                  updateServiceRequest({
                    ...serviceRequest,
                    newBinBundle: (e.target as HTMLInputElement).value,
                  });
                }}>
                <label
                  htmlFor="Low waste, small bin bundle - 80L rubbish Bin and 140L recycling bin - $210*"
                  className="flex flex-row items-center w-full cursor-pointer">
                  <input
                    id="Low waste, small bin bundle - 80L rubbish Bin and 140L recycling bin - $210*"
                    name="ChangeBinSizes"
                    type="radio"
                    value="Low waste, small bin bundle - 80L rubbish Bin and 140L recycling bin"
                    className="mr-2 cursor-pointer"
                    required={true}
                  />
                  Low waste, small bin bundle - 80L rubbish Bin and 140L
                  recycling bin - $210*
                </label>
                <label
                  htmlFor="Standard bin bundle (default) - 140L rubbish bin and 240L recycling bin - $245*"
                  className="flex flex-row items-center w-full cursor-pointer">
                  <input
                    id="Standard bin bundle (default) - 140L rubbish bin and 240L recycling bin - $245*"
                    name="ChangeBinSizes"
                    type="radio"
                    value="Standard bin bundle (default) - 140L rubbish bin and 240L recycling bin"
                    className="mr-2 cursor-pointer"
                  />
                  Standard bin bundle - 140L rubbish bin and 240L recycling bin
                  - $245*
                </label>
                <label
                  htmlFor="High waste, large bin bundle - 240L rubbish bin and 240L recycling bin - $350*"
                  className="flex flex-row items-center w-full cursor-pointer">
                  <input
                    id="High waste, large bin bundle - 240L rubbish bin and 240L recycling bin - $350*"
                    name="ChangeBinSizes"
                    type="radio"
                    value="High waste, large bin bundle - 240L rubbish bin and 240L recycling bin"
                    className="mr-2 cursor-pointer"
                  />
                  High waste, large bin bundle - 240L rubbish bin and 240L
                  recycling bin - $350*
                </label>
              </div>
            </div>
          </div>
        </motion.div>
      ) : null}
      {serviceRequest.serviceRequest ===
        FixedServiceRequestTypes.GARDEN_WASTE_NEW ||
      serviceRequest.serviceRequest ===
        FixedServiceRequestTypes.GARDEN_WASTE_CHANGE ? (
        <motion.div variants={animationVariants} className="w-full">
          <div className="flex flex-col items-start w-full mb-4 bg-white rounded px-4 py-4  border border-gray-300 shadow-sm">
            <label
              htmlFor="greenWasteFrequency"
              className="text-base text-gray-500 pb-4">
              {serviceRequest.serviceRequest ===
              FixedServiceRequestTypes.GARDEN_WASTE_NEW
                ? "Select your garden waste collection frequency"
                : "Select the garden waste collection frequency you would like to change to"}
            </label>
            <div className="flex flex-col w-full">
              <div
                className="grid grid-cols-1 sm:grid-cols-2 sm:gap-0 gap-2 w-full"
                onChange={(e) =>
                  updateServiceRequest({
                    ...serviceRequest,
                    gardenWasteFrequency: (e.target as HTMLInputElement).value,
                  })
                }>
                <label
                  htmlFor="Fortnightly"
                  className="flex flex-row items-center w-full cursor-pointer">
                  <input
                    id="Fortnightly"
                    name="greenWasteFrequency"
                    type="radio"
                    value="Fortnightly"
                    className="mr-2 cursor-pointer"
                    required={true}
                  />
                  Fortnightly ($110 for the year)
                </label>
                <label
                  htmlFor="Every four weeks"
                  className="flex flex-row items-center w-full cursor-pointer">
                  <input
                    id="Every four weeks"
                    name="greenWasteFrequency"
                    type="radio"
                    value="Every four weeks"
                    className="mr-2 cursor-pointer"
                  />
                  Every four weeks ($80 for the year)
                </label>
              </div>
            </div>
          </div>
        </motion.div>
      ) : null}
      {serviceRequest.serviceRequest ===
      FixedServiceRequestTypes.DAMAGED_BIN ? (
        <motion.div variants={animationVariants} className="w-full">
          <StyledTextArea
            label="Please describe the damage and add any additional comments"
            required={true}
            onChange={(e) =>
              updateServiceRequest({
                ...serviceRequest,
                comments: (e.target as HTMLInputElement).value,
              })
            }
          />
        </motion.div>
      ) : (
        <motion.div variants={animationVariants} className="w-full">
          <StyledTextArea
            label="Add any additional comments (optional)"
            required={false}
            onChange={(e) =>
              updateServiceRequest({
                ...serviceRequest,
                comments: (e.target as HTMLInputElement).value,
              })
            }
          />
        </motion.div>
      )}
      <motion.div variants={animationVariants} className="w-full">
        <StyledInput
          label="Enter your full name"
          onChange={(e) =>
            updateServiceRequest({
              ...serviceRequest,
              name: (e.target as HTMLInputElement).value,
            })
          }
        />
      </motion.div>
      <motion.div variants={animationVariants} className="w-full">
        <StyledInput
          label="Enter your email address"
          type="email"
          onChange={(e) =>
            updateServiceRequest({
              ...serviceRequest,
              emailAddress: (e.target as HTMLInputElement).value,
            })
          }
        />
      </motion.div>
      <motion.div variants={animationVariants} className="w-full">
        <StyledInput
          label="Enter your phone number"
          type="number"
          onChange={(e) =>
            updateServiceRequest({
              ...serviceRequest,
              phoneNo: (e.target as HTMLInputElement).value,
            })
          }
        />
      </motion.div>
      {serviceRequest.serviceRequest ===
        FixedServiceRequestTypes.ADDITIONAL_BIN ||
      serviceRequest.serviceRequest ===
        FixedServiceRequestTypes.GARDEN_WASTE_NEW ||
      serviceRequest.serviceRequest ===
        FixedServiceRequestTypes.GARDEN_WASTE_CHANGE ||
      serviceRequest.serviceRequest ===
        FixedServiceRequestTypes.CHANGE_BIN_SIZES ||
      serviceRequest.serviceRequest ===
        FixedServiceRequestTypes.CANCEL_GARDEN_WASTE ||
      serviceRequest.serviceRequest ===
        FixedServiceRequestTypes.NEW_PROPERTY_BIN ? (
        <div className="w-full">
          <motion.div variants={animationVariants} className="w-full flex">
            <div className="w-full -mr-7">
              <StyledInput
                label="Enter your rates valuation number (no special characters)"
                onChange={(e) => {
                  updateAssessmentNoError(null);
                  updateServiceRequest({
                    ...serviceRequest,
                    assessmentNumber: (e.target as HTMLInputElement).value,
                  });
                }}
              />
            </div>
            <QuestionMark onClick={() => setIsHelperModalOpen(true)} />
          </motion.div>
          {assessmentNoError && (
            <motion.div
              variants={animationVariants}
              className="mb-4 bg-white inline-block rounded w-full">
              <div className="m-2 p-2 relative border border-red-500 rounded flex items-center justify-end">
                <p className="mr-0 flex-grow text-red-500 ">
                  {assessmentNoError}
                </p>
                <div className="flex-shrink-0 mr-2">
                  <ExclamationMark />
                </div>
              </div>
            </motion.div>
          )}
          <motion.div variants={animationVariants} className="w-full flex">
            <div className="w-full -mr-7">
              <StyledInput
                label="Enter your customer number (no special characters)"
                type="number"
                onChange={(e) => {
                  updateCustomerNoError(null);
                  updateServiceRequest({
                    ...serviceRequest,
                    customerNumber: (e.target as HTMLInputElement).value,
                  });
                }}
              />
            </div>
            <QuestionMark onClick={() => setIsHelperModalOpen(true)} />
          </motion.div>
          {customerNoError && (
            <motion.div
              variants={animationVariants}
              className="mb-4 bg-white inline-block rounded w-full">
              <div className="m-2 p-2 relative border border-red-500 rounded flex items-center justify-end">
                <p className="mr-0 flex-grow text-red-500 ">
                  {customerNoError}
                </p>
                <div className="flex-shrink-0 mr-2">
                  <ExclamationMark />
                </div>
              </div>
            </motion.div>
          )}
        </div>
      ) : null}
      {serviceRequest.serviceRequest ===
        FixedServiceRequestTypes.MISSED_COLLECTION && (
        <div className="w-full">
          <motion.div variants={animationVariants} className="w-full">
            <StyledRadio
              id="collectionTimeRadio"
              label="Was your bin at the collection point by 7:00am?"
              onChange={(e) =>
                updateOnCollectionTime(
                  helpers.convertStrToBool((e.target as HTMLInputElement).value)
                )
              }
            />
          </motion.div>
          {onCollectionTime === false && (
            <motion.div
              variants={animationVariants}
              className="mb-4 bg-white inline-block rounded w-full">
              <div className="m-2 p-2 relative border border-red-500 rounded flex items-center justify-end">
                <p className="mr-0 flex-grow text-red-500 ">
                  Bins need to be at the collection point by 7am to ensure they
                  are collected. Please put your bin(s) out by 7am on your next
                  collection day.
                </p>
                <div className="flex-shrink-0 mr-2">
                  <ExclamationMark />
                </div>
              </div>
            </motion.div>
          )}
          <motion.div variants={animationVariants} className="w-full">
            <StyledRadio
              id="contaminationStickerRadio"
              label="Does your bin contain a non-collection tag or a contamination sticker?"
              onChange={(e) =>
                updateHasContaminatedSticker(
                  helpers.convertStrToBool((e.target as HTMLInputElement).value)
                )
              }
            />
          </motion.div>
          {hasContaminatedSticker === true && (
            <motion.div
              variants={animationVariants}
              className="mb-4 bg-white inline-block rounded w-full">
              <div className="m-2 p-2 relative border border-red-500 rounded flex items-center justify-end">
                <p className="mr-0 flex-grow text-red-500 ">
                  Please refer to the tag/sticker applied to your bin for
                  instructions.
                </p>
                <div className="flex-shrink-0 mr-2">
                  <ExclamationMark />
                </div>
              </div>
            </motion.div>
          )}
          <motion.div variants={animationVariants} className="w-full">
            <StyledRadio
              id="correctWeekRadio"
              label="Have you double checked that you have put your bin out on the correct collection day/week?"
              onChange={(e) =>
                updateIsCorrectWeek(
                  helpers.convertStrToBool((e.target as HTMLInputElement).value)
                )
              }
            />
          </motion.div>
          {isCorrectWeek === false && (
            <motion.div
              variants={animationVariants}
              className="mb-4 bg-white inline-block rounded w-full">
              <div className="m-2 p-2 border border-red-500 rounded flex items-center justify-end">
                <p className="mr-2 flex-grow text-red-500 ">
                  Please refer to the collection calendar to find out what bins
                  go out on which week. You will need to check the side of your
                  bin to see if you fall in week 1 or week 2.
                </p>
                <div className="flex-shrink-0 mr-2">
                  <ExclamationMark />
                </div>
              </div>
            </motion.div>
          )}
          <motion.div variants={animationVariants} className="w-full">
            <StyledRadio
              id="otherBinsMissedRadio"
              label="Have any other bins been missed on your street?"
              onChange={(e) => {
                updateServiceRequest({
                  ...serviceRequest,
                  missedCollectionDetails: {
                    ...serviceRequest.missedCollectionDetails,
                    otherBinsMissed: helpers.convertStrToBool(
                      (e.target as HTMLInputElement).value
                    ),
                  },
                });
              }}
            />
          </motion.div>
          <motion.div variants={animationVariants} className="w-full">
            <StyledRadio
              id="mudRadio"
              label="Do you live in a retirement village or apartment complex?"
              onChange={(e) =>
                updateServiceRequest({
                  ...serviceRequest,
                  missedCollectionDetails: {
                    ...serviceRequest.missedCollectionDetails,
                    isMUD: helpers.convertStrToBool(
                      (e.target as HTMLInputElement).value
                    ),
                  },
                })
              }
            />
          </motion.div>
        </div>
      )}
      <motion.div variants={animationVariants} className="w-full">
        <div className="bg-white rounded border border-gray-300 shadow-sm px-4 py-3">
          <div className="flex flex-col md:flex-row justify-between">
            <p className="text-base text-grey-500 mb-2 inline-flex">
              Enter your address in the field below
            </p>
            <label
              htmlFor="manual-address"
              className="text-base text-gray-500 inline-flex flex-row items-center mb-4 select-none cursor-pointer">
              Let me manually enter my address
              <input
                name="manual-address"
                id="manual-address"
                type="checkbox"
                checked={manualAddress}
                className="ml-2 h-5 cursor-pointer"
                onChange={() => updateManualAddress(!manualAddress)}
              />
            </label>
          </div>
          {manualAddress ? (
            <div className="w-full">
              {isValidAddress &&
                serviceRequest.addressComponents.StreetNumber === "" && (
                  <div className="mb-4 flex flex-row items-center w-full px-4 py-3 border border-gray-300 rounded">
                    <label
                      htmlFor="Enter your street number"
                      className="text-base text-gray-500 flex-grow mr-4">
                      Sorry, we couldn't find an address with your street
                      number. This can happen with new properties or
                      subdivisions. Please enter your address manually before
                      proceeding.
                    </label>
                    <div className="flex-shrink-0 mr-2 mb-2">
                      <ExclamationMark color="#a2a2a2" />
                    </div>
                  </div>
                )}
              <div className="w-full grid grid-cols-3 gap-x-4">
                <StyledInput
                  label="Street number"
                  type="text"
                  margin="mb-0"
                  onChange={(e) =>
                    updateBackupStreetNo(
                      (e.target as HTMLInputElement).value.trim()
                    )
                  }
                />
                <StyledInput
                  label="Street name"
                  type="text"
                  margin="mb-0"
                  onChange={(e) =>
                    updateBackupStreetName(
                      (e.target as HTMLInputElement).value.trim()
                    )
                  }
                />
                <StyledInput
                  label="Post code"
                  type="number"
                  margin="mb-0"
                  onChange={(e) =>
                    updateBackupPostCode(
                      (e.target as HTMLInputElement).value.trim()
                    )
                  }
                />
              </div>
            </div>
          ) : (
            <AddressAutocomplete
              label="Start typing your address"
              serviceRequest={serviceRequest}
              updateServiceRequest={updateServiceRequest}
              addressErrorMessage={addressErrorMessage}
              updateAddressErrorMessage={updateAddressErrorMessage}
              isValidAddress={isValidAddress}
              updateIsValidAddress={updateIsValidAddress}
              updateBackupStreetNo={updateBackupStreetNo}
              updateBackupPostcode={updateBackupPostCode}
              updateManualAddress={updateManualAddress}
            />
          )}
        </div>
      </motion.div>
      {serviceRequest.serviceRequest ===
        FixedServiceRequestTypes.ADDITIONAL_BIN && (
        <motion.div
          variants={animationVariants}
          className="mb-4 mt-4 h-full w-full flex flex-col border border-gray-300 shadow-sm px-3 py-3 rounded text-sm bg-white">
          <p className="text-sm text-gray-800 mb-2">
            Please note that there are costs associated with ordering additional
            bins and the ratepayer will be liable for these. Below are the
            annual costs for each additional bin for the period from 1 July 2024
            to 30 June 2025:
          </p>
          {
            // iterate through additional bin list to pull out prices
            Object.entries(FixedServiceTypes.additionalBinServiceList).map(
              ([id, price]) => (
                <p className="text-sm text-gray-800">{price}</p>
              )
            )
          }
          <p className="text-sm text-gray-800 mt-3">
            We will send you an invoice for the cost of the additional bin(s)
            upon approval of your request, or in July if requested in April –
            June.
          </p>
          <p className="text-sm text-gray-800 mt-3">
            In clicking ‘Submit’, I confirm I am the ratepayer of this property,
            or authorised by the ratepayer to submit this request on their
            behalf. I have read and agree to the{" "}
            <a
              href="https://cms.environz.co.nz/media/Terms-and-Conditions-for-Additional-Bins.pdf"
              className="underline"
              target="_blank"
              rel="noreferrer">
              Additional Bins Terms and Conditions
            </a>
            ,{" "}
            <a
              href="https://cms.environz.co.nz/media/Website-Privacy-Statement.pdf"
              className="underline"
              target="_blank"
              rel="noreferrer">
              Privacy Statement
            </a>{" "}
            and the{" "}
            <a
              href="https://cms.environz.co.nz/media/Website-App-Terms-of-Use.pdf"
              className="underline"
              target="_blank"
              rel="noreferrer">
              Website/App Terms of Use
            </a>
            .
          </p>
        </motion.div>
      )}
      {serviceRequest.serviceRequest ===
        FixedServiceRequestTypes.GARDEN_WASTE_NEW ||
      serviceRequest.serviceRequest ===
        FixedServiceRequestTypes.GARDEN_WASTE_CHANGE ? (
        <motion.div
          variants={animationVariants}
          className="my-4 h-full w-full flex flex-col border border-gray-300 shadow-sm px-3 py-3 rounded bg-white text-sm text-gray-800">
          <p>
            Please note that there are costs associated with the garden waste 
            service and the ratepayer will be responsible for these. The initial 
            cost of the garden waste service will be invoiced to the ratepayer upon 
            signing up and the amount that users are charged will differ, depending 
            on when they register:
            <br />
          </p>
          <p className="mt-3">
            &#8226; Between 1 July – 31 December: full annual charge* (currently $80 for 
            4-weekly collections, $110 for fortnightly collections, for rates year 
            1 July 2024 to 30 June 2025) 
          </p>
          <p className="mt-3">
            &#8226; Between 1 January – 31 May: 50% off full annual charge 
          </p>
          <p className="mt-3">
            &#8226; Between 1 April – 30 June: no charge 
          </p>
          <p className="mt-3">
            *The full annual charge for the garden waste service will then be added to your property rates for the following rates years (from 1 July), unless you cancel your service before 30 June. 
          </p>
          <p className="mt-3">
            In clicking ‘Submit’, I confirm I am the ratepayer of this property,
            or authorised by the ratepayer to submit this request on their
            behalf. I have read and agree to the{" "}
            <a
              href="https://www.tauranga.govt.nz/Portals/0/data/living/rubbish_recycling/files/garden-waste-terms-conditions.pdf"
              className="underline"
              target="_blank"
              rel="noreferrer">
              Garden Waste Terms and Conditions
            </a>
            ,{" "}
            <a
              href="https://cms.environz.co.nz/media/Website-Privacy-Statement.pdf"
              className="underline"
              target="_blank"
              rel="noreferrer">
              Privacy Statement
            </a>{" "}
            and the{" "}
            <a
              href="https://cms.environz.co.nz/media/Website-App-Terms-of-Use.pdf"
              className="underline"
              target="_blank"
              rel="noreferrer">
              Website/App Terms of Use
            </a>
            .
          </p>
        </motion.div>
      ) : null}
      {serviceRequest.serviceRequest ===
        FixedServiceRequestTypes.CANCEL_GARDEN_WASTE && (
        <motion.div
          variants={animationVariants}
          className="my-4 h-full w-full flex flex-col border border-gray-300 shadow-sm px-3 py-3 rounded bg-white text-sm text-gray-800">
          <p>
            Please note if you cancel your garden waste service before 30 June
            in any given year, you will not be refunded for the remaining period
            under the existing rates year, but you will not be charged in the
            following rates year. If you are selling your property, please leave
            it up to the new property owner to cancel the garden waste service
            if they wish to.
            <br />
          </p>
          <p className="mt-3">
            In clicking ‘Submit’, I confirm I am the ratepayer of this property,
            or authorised by the ratepayer to submit this request on their
            behalf. I have read and agree to the{" "}
            <a
              href="https://cms.environz.co.nz/media/Garden-Waste-Terms-and-Conditions-updated-15-February-2023-PDF.pdf"
              className="underline"
              target="_blank"
              rel="noreferrer">
              Garden Waste Terms and Conditions
            </a>
            ,{" "}
            <a
              href="https://cms.environz.co.nz/media/Website-Privacy-Statement.pdf"
              className="underline"
              target="_blank"
              rel="noreferrer">
              Privacy Statement
            </a>{" "}
            and the{" "}
            <a
              href="https://cms.environz.co.nz/media/Website-App-Terms-of-Use.pdf"
              className="underline"
              target="_blank"
              rel="noreferrer">
              Website/App Terms of Use
            </a>
            .
          </p>
        </motion.div>
      )}
      {serviceRequest.serviceRequest ===
        FixedServiceRequestTypes.CHANGE_BIN_SIZES && (
        <motion.div
          variants={animationVariants}
          className="mb-4 mt-4 h-full w-full flex flex-col border border-gray-300 shadow-sm px-3 py-3 rounded text-sm bg-white">
          <p className="text-sm text-gray-800">
            Please note that there are costs associated with changing the size
            of your bins and the ratepayer will be liable for these. Below are
            the annual costs for the different bin bundles for the period from 1
            July 2024 to 30 June 2025:
          </p>
          <p className="text-sm text-gray-800 mt-3">
            Low waste bin bundle cost $210 – a reduction of $35 for the year off
            the targeted rate{" "}
          </p>
          <p className="text-sm text-gray-800 mt-2">
            Standard waste bin bundle cost $245{" "}
          </p>
          <p className="text-sm text-gray-800 mt-2">
            High waste bin bundle cost $350 – an additional $105 for the year on
            top of the targeted rate{" "}
          </p>          
          <p className="text-sm text-gray-800 mt-3">
            In clicking ‘Submit’, I confirm I am the ratepayer of this property,
            or authorised by the ratepayer to submit this request on their
            behalf. I have read and agree to the{" "}
            <a
              href="https://www.tauranga.govt.nz/portals/0/data/living/rubbish_recycling/files/terms-and-conditions-changing-bin-size.pdf"
              className="underline"
              target="_blank"
              rel="noreferrer">
              Changing Bin Size Terms and Conditions
            </a>
            ,{" "}
            <a
              href="https://cms.environz.co.nz/media/Website-Privacy-Statement.pdf"
              className="underline"
              target="_blank"
              rel="noreferrer">
              Privacy Statement
            </a>{" "}
            and the{" "}
            <a
              href="https://cms.environz.co.nz/media/Website-App-Terms-of-Use.pdf"
              className="underline"
              target="_blank"
              rel="noreferrer">
              Website/App Terms of Use
            </a>
            .
          </p>
        </motion.div>
      )}
      {serviceRequest.serviceRequest ===
        FixedServiceRequestTypes.MISSED_COLLECTION && (
        <motion.div
          variants={animationVariants}
          className="mb-4 mt-4 h-full w-full flex flex-col border border-gray-300 shadow-sm px-3 py-3 rounded text-sm bg-white">
          <p className="text-sm text-gray-800 mb-3">
            Please note video footage from the collection vehicles will be
            checked to verify your bin/s has been missed. If the footage shows
            that your bin was not out on time, overfull, or tagged for any
            reason, the contractor will not return to collect your bin. If your
            bin was serviced but not emptied completely, please call Tauranga
            City Council on 07 577 7000.
          </p>
          <p className="text-sm text-gray-800">
            In clicking ‘Submit’, I confirm I have read and agree to the{" "}
            <a
              href="https://cms.environz.co.nz/media/Website-Privacy-Statement.pdf"
              className="underline"
              target="_blank"
              rel="noreferrer">
              Privacy Statement
            </a>{" "}
            and{" "}
            <a
              href="https://cms.environz.co.nz/media/Website-App-Terms-of-Use.pdf"
              className="underline"
              target="_blank"
              rel="noreferrer">
              Website/App Terms of Use
            </a>
            .
          </p>
        </motion.div>
      )}
      {serviceRequest.serviceRequest ===
        FixedServiceRequestTypes.NEW_PROPERTY_BIN && (
        <motion.div
          variants={animationVariants}
          className="mb-4 mt-4 h-full w-full flex flex-col border border-gray-300 shadow-sm px-3 py-3 rounded text-sm bg-white">
          <p>
            Please note the charge for this service will apply from the next
            rating year on 1 July. Below are the annual costs for the different
            bin bundles for the period from 1 July 2024 to 30 June 2025:
            <br />
          </p>
          <p className="mt-3">
            Low waste bin bundle – $210
            <br />
            Standard bin bundle – $245
            <br />
            High waste bin bundle – $350
          </p>
          <p className="text-sm text-gray-800 mb-3 mt-3">
            In clicking ‘Submit’, I confirm I am the ratepayer of this property,
            or authorised by the ratepayer to submit this request on their
            behalf. I have read and agree to the{" "}
            <a
              href="https://cms.environz.co.nz/media/Terms-and-Conditions-Bins-for-new-property.pdf"
              className="underline"
              target="_blank"
              rel="noreferrer">
              Bins for New Property Terms and Conditions
            </a>
            ,{" "}
            <a
              href="https://cms.environz.co.nz/media/Website-Privacy-Statement.pdf"
              className="underline"
              target="_blank"
              rel="noreferrer">
              Privacy Statement
            </a>{" "}
            and{" "}
            <a
              href="https://cms.environz.co.nz/media/Website-App-Terms-of-Use.pdf"
              className="underline"
              target="_blank"
              rel="noreferrer">
              Website/App Terms of Use
            </a>
            .
          </p>
        </motion.div>
      )}
      {serviceRequest.serviceRequest !==
        FixedServiceRequestTypes.ADDITIONAL_BIN &&
      serviceRequest.serviceRequest !==
        FixedServiceRequestTypes.GARDEN_WASTE_NEW &&
      serviceRequest.serviceRequest !==
        FixedServiceRequestTypes.GARDEN_WASTE_CHANGE &&
      serviceRequest.serviceRequest !==
        FixedServiceRequestTypes.CHANGE_BIN_SIZES &&
      serviceRequest.serviceRequest !==
        FixedServiceRequestTypes.MISSED_COLLECTION &&
      serviceRequest.serviceRequest !==
        FixedServiceRequestTypes.CANCEL_GARDEN_WASTE &&
      serviceRequest.serviceRequest !==
        FixedServiceRequestTypes.NEW_PROPERTY_BIN ? (
        <motion.div
          variants={animationVariants}
          className="my-4 h-full w-full flex flex-col">
          <p className="border border-gray-300 shadow-sm px-3 py-3 rounded bg-white text-sm text-gray-800">
            In clicking ‘Submit’, I confirm I have read and agree to the{" "}
            <a
              href="https://cms.environz.co.nz/media/Website-Privacy-Statement.pdf"
              className="underline"
              target="_blank"
              rel="noreferrer">
              Privacy Statement
            </a>{" "}
            and{" "}
            <a
              href="https://cms.environz.co.nz/media/Website-App-Terms-of-Use.pdf"
              className="underline"
              target="_blank"
              rel="noreferrer">
              Website/App Terms of Use
            </a>
            .
          </p>
        </motion.div>
      ) : null}
      {formErrorMessage && (
        <motion.div
          variants={animationVariants}
          className="mb-4 bg-white rounded w-full">
          <div className="m-2 p-2 relative border border-red-500 rounded flex items-center justify-end">
            <p className="mr-0 flex-grow text-red-500 ">{formErrorMessage}</p>
            <div className="flex-shrink-0 mr-2">
              <ExclamationMark />
            </div>
          </div>
        </motion.div>
      )}
      <motion.div
        variants={animationVariants}
        className="w-full flex justify-end">
        <button
          type="submit"
          disabled={
            (!manualAddress && addressErrorMessage !== null) ||
            checkboxErrorMessage !== null ||
            assessmentNoError !== null ||
            customerNoError !== null ||
            onCollectionTime === false ||
            isCorrectWeek === false ||
            hasContaminatedSticker === true
          }
          className="body-font disabled-button-style w-28 xl:w-40 text-white bg-tcc-green transition duration-300 ease-in-out hover:bg-tcc-green-lighter rounded py-2.5 md:py-3 focus:outline-none border border-transparent focus:border-gray-800">
          {isLoading ? <DotSpinner /> : "Submit"}
        </button>
      </motion.div>
      <ConfirmationModal
        isModalOpen={isConfirmationModalOpen}
        setIsModalOpen={setIsConfirmationModalOpen}
        formatedAddress={
          manualAddress
            ? backupStreetNo + " " + backupStreetName
            : serviceRequest.addressComponents.FormattedAddress
        }
        services={serviceRequest.service}
        onSubmit={onSubmit}
        serviceRequest={serviceRequest.serviceRequest}
        frequency={serviceRequest.gardenWasteFrequency}
        binBundle={serviceRequest.newBinBundle}
      />
      <HelperModal
        open={isHelperModalOpen}
        onClose={() => setIsHelperModalOpen(false)}
      />
    </motion.form>
  );
}

export default StandardServicePath;
