import AddressAutocomplete from "../../components/AddressAutocomplete/AddressAutocomplete";
import ExclamationMark from "../../components/ExclamationMark/ExclamationMark";
import StyledTextArea from "../../components/StyledInputs/StyledTextArea";
import StyledInput from "../../components/StyledInputs/StyledInput";
import StyledRadio from "../../components/StyledInputs/StyledRadio";
import DotSpinner from "../../components/DotSpinner/DotSpinner";
import Select, { MultiValue } from "react-select";
import Map from "../../components/Map/Map";
import {
  animationVariants,
  fixedIllegalDumpingDetails,
} from "../../core/constants/constants";
import { IIllegalDumpingPath } from "../../core/types/types";
import { helpers } from "../../core/services/helpers";
import { motion } from "framer-motion";
import { FormEvent, useState } from "react";

function IllegalDumpingPath({
  serviceRequest,
  updateServiceRequest,
  onSubmit,
  isLoading,
  formErrorMessage,
  updateOtherWasteDetails,
  wasteDescError,
  updateWasteDescError,
}: IIllegalDumpingPath) {
  const [addressErrorMessage, updateAddressErrorMessage] = useState<
    string | null
  >(null);
  const [isValidAddress, updateIsValidAddress] = useState(false);

  function handleWasteDetails(e: MultiValue<{ value: string; label: string }>) {
    updateWasteDescError(null);

    let currentSelectedItems = [];
    for (let i = 0; i < e.length; i++) {
      const value: string = e[i].value;
      currentSelectedItems.push(value);
    }

    if (currentSelectedItems.length > 0) {
      updateServiceRequest({
        ...serviceRequest,
        wasteDetails: {
          ...serviceRequest.wasteDetails,
          wasteDescription: currentSelectedItems.join(", "),
        },
      });
    } else {
      updateServiceRequest({
        ...serviceRequest,
        wasteDetails: {
          ...serviceRequest.wasteDetails,
          wasteDescription: null,
        },
      });
    }
  }

  const customStyles = {
    valueContainer: (provided: any, state: any) => ({
      ...provided,
      padding: 12,
    }),
  };

  return (
    <motion.form
      variants={animationVariants}
      initial="initial"
      animate="in"
      exit="out"
      className="flex flex-col items-center w-full"
      onSubmit={(e: FormEvent) => {
        e.preventDefault();
        // prevents double submission
        if (!isLoading) {
          onSubmit(false, false);
        }
      }}>
      <motion.div variants={animationVariants} className="w-full mb-4">
        <Select
          options={fixedIllegalDumpingDetails}
          styles={customStyles}
          placeholder="Select what has been dumped"
          isSearchable={false}
          isMulti
          closeMenuOnSelect={false}
          onChange={handleWasteDetails}
        />
        {wasteDescError && (
          <div className="mt-4 bg-white inline-block rounded w-full">
            <div className="m-2 p-2 relative border border-red-500 rounded flex items-center justify-end">
              <p className="mr-0 flex-grow text-red-500 ">{wasteDescError}</p>
              <div className="flex-shrink-0 mr-2">
                <ExclamationMark />
              </div>
            </div>
          </div>
        )}
      </motion.div>
      {serviceRequest.wasteDetails.wasteDescription !== null &&
      serviceRequest.wasteDetails.wasteDescription.includes("Other") ? (
        <motion.div variants={animationVariants} className="w-full">
          <StyledInput
            label={`Please describe what other waste has been dumped`}
            onChange={(e) => {
              updateWasteDescError(null);
              updateOtherWasteDetails((e.target as HTMLInputElement).value);
            }}
          />
        </motion.div>
      ) : (
        <div></div>
      )}
      <motion.div variants={animationVariants} className="w-full">
        <StyledInput
          label="What is the approximate volume of the waste? (e.g. size of a rubbish bag, trailer load, etc.)"
          onChange={(e) =>
            updateServiceRequest({
              ...serviceRequest,
              wasteDetails: {
                ...serviceRequest.wasteDetails,
                approxAmount: (e.target as HTMLInputElement).value,
              },
            })
          }
        />
      </motion.div>

      <motion.div variants={animationVariants} className="w-full">
        <StyledInput
          label="How long has the waste been in the area?"
          onChange={(e) =>
            updateServiceRequest({
              ...serviceRequest,
              wasteDetails: {
                ...serviceRequest.wasteDetails,
                duration: (e.target as HTMLInputElement).value,
              },
            })
          }
        />
      </motion.div>
      <motion.div variants={animationVariants} className="w-full">
        <StyledInput
          label="Has this type of dumping occurred in the past?"
          onChange={(e) =>
            updateServiceRequest({
              ...serviceRequest,
              wasteDetails: {
                ...serviceRequest.wasteDetails,
                occurredPreviously: helpers.convertStrToBool(
                  (e.target as HTMLInputElement).value
                ),
              },
            })
          }
        />
      </motion.div>
      <motion.div variants={animationVariants} className="w-full">
        <StyledRadio
          id="hazardousItems"
          label="Does the dumped material include hazardous items? (e.g. broken glass, needles, etc.)"
          onChange={(e) => {
            updateServiceRequest({
              ...serviceRequest,
              wasteDetails: {
                ...serviceRequest.wasteDetails,
                hazardousItems: helpers.convertStrToBool(
                  (e.target as HTMLInputElement).value
                ),
              },
            });
          }}
        />
      </motion.div>
      <motion.div variants={animationVariants} className="w-full">
        <StyledRadio
          id="didWitness"
          label="Did you witness the dumping?"
          onChange={(e) => {
            updateServiceRequest({
              ...serviceRequest,
              wasteDetails: {
                ...serviceRequest.wasteDetails,
                didWitness: helpers.convertStrToBool(
                  (e.target as HTMLInputElement).value
                ),
              },
            });
          }}
        />
      </motion.div>
      {serviceRequest.wasteDetails.didWitness === true && (
        <motion.div variants={animationVariants} className="w-full">
          <StyledInput
            label="Details of the dumping, e.g. number plate, make and/or colour of vehicle, brief description of the person (optional)"
            required={false}
            onChange={(e) =>
              updateServiceRequest({
                ...serviceRequest,
                wasteDetails: {
                  ...serviceRequest.wasteDetails,
                  whoDumped: (e.target as HTMLInputElement).value,
                },
              })
            }
          />
        </motion.div>
      )}
      <motion.div variants={animationVariants} className="w-full">
        <StyledTextArea
          label="Add any additional comments (optional)"
          required={false}
          onChange={(e) =>
            updateServiceRequest({
              ...serviceRequest,
              comments: (e.target as HTMLInputElement).value,
            })
          }
        />
      </motion.div>
      <motion.div variants={animationVariants} className="w-full">
        <StyledInput
          label="Enter your full name"
          onChange={(e) =>
            updateServiceRequest({
              ...serviceRequest,
              name: (e.target as HTMLInputElement).value,
            })
          }
        />
      </motion.div>
      <motion.div variants={animationVariants} className="w-full">
        <StyledInput
          label="Enter your email address"
          type="email"
          onChange={(e) =>
            updateServiceRequest({
              ...serviceRequest,
              emailAddress: (e.target as HTMLInputElement).value,
            })
          }
        />
      </motion.div>
      <motion.div variants={animationVariants} className="w-full">
        <StyledInput
          label="Enter your phone number"
          type="number"
          onChange={(e) =>
            updateServiceRequest({
              ...serviceRequest,
              phoneNo: (e.target as HTMLInputElement).value,
            })
          }
        />
      </motion.div>
      <motion.div variants={animationVariants} className="w-full z-50">
        <AddressAutocomplete
          label="Enter the approximate address"
          serviceRequest={serviceRequest}
          updateServiceRequest={updateServiceRequest}
          addressErrorMessage={addressErrorMessage}
          updateAddressErrorMessage={updateAddressErrorMessage}
          isValidAddress={isValidAddress}
          updateIsValidAddress={updateIsValidAddress}
        />
      </motion.div>
      <motion.div variants={animationVariants} className="w-full mt-4">
        <Map
          serviceRequest={serviceRequest}
          updateServiceRequest={updateServiceRequest}
        />
      </motion.div>
      <motion.div
        variants={animationVariants}
        className="mb-4 h-full w-full flex flex-col border border-gray-300 shadow-sm px-3 py-3 rounded text-sm bg-white">
        <p className="text-sm text-gray-800 mb-3">
          If you have photos or video footage to support this report, please
          email them to sustainability.waste@tauranga.govt.nz, quoting the
          reference number supplied on the next page after clicking Submit.
        </p>
        <p className="text-sm text-gray-800">
          In clicking ‘Submit’, I confirm I have read and agree to the{" "}
          <a
            href="https://cms.environz.co.nz/media/Website-Privacy-Statement.pdf"
            className="underline"
            target="_blank"
            rel="noreferrer">
            Privacy Statement
          </a>{" "}
          and{" "}
          <a
            href="https://cms.environz.co.nz/media/Website-App-Terms-of-Use.pdf"
            className="underline"
            target="_blank"
            rel="noreferrer">
            Website/App Terms of Use
          </a>
          .
        </p>
      </motion.div>
      {formErrorMessage && (
        <motion.div
          variants={animationVariants}
          className="mb-4 bg-white rounded w-full">
          <div className="m-2 p-2 relative border border-red-500 rounded flex items-center justify-end">
            <p className="mr-0 flex-grow text-red-500 ">{formErrorMessage}</p>
            <div className="flex-shrink-0 mr-2">
              <ExclamationMark />
            </div>
          </div>
        </motion.div>
      )}
      <motion.div
        variants={animationVariants}
        className="w-full flex items-start justify-end">
        <button
          type="submit"
          disabled={addressErrorMessage !== null || wasteDescError !== null}
          className="body-font disabled-button-style flex-shrink-0 w-28 xl:w-40 text-white bg-tcc-green transition duration-300 ease-in-out hover:bg-tcc-green-lighter rounded py-2.5 md:py-3 focus:outline-none border border-transparent focus:border-gray-800">
          {isLoading ? <DotSpinner /> : "Submit"}
        </button>
      </motion.div>
    </motion.form>
  );
}

export default IllegalDumpingPath;
