import AddressAutocomplete from "../../components/AddressAutocomplete/AddressAutocomplete";
import ExclamationMark from "../../components/ExclamationMark/ExclamationMark";
import StyledTextArea from "../../components/StyledInputs/StyledTextArea";
import StyledSelect from "../../components/StyledInputs/StyledSelect";
import StyledInput from "../../components/StyledInputs/StyledInput";
import StyledRadio from "../../components/StyledInputs/StyledRadio";
import DotSpinner from "../../components/DotSpinner/DotSpinner";
import Map from "../../components/Map/Map";
import {
  animationVariants,
  FixedLooseLitterTypes,
} from "../../core/constants/constants";
import { ILooseLitterPath } from "../../core/types/types";
import { motion } from "framer-motion";
import { FormEvent, useState } from "react";
import { helpers } from "../../core/services/helpers";

function LooseLitterPath({
  serviceRequest,
  updateServiceRequest,
  onSubmit,
  isLoading,
  formErrorMessage,
}: ILooseLitterPath) {
  const [addressErrorMessage, updateAddressErrorMessage] = useState<
    string | null
  >(null);
  const [isValidAddress, updateIsValidAddress] = useState(false);

  return (
    <motion.form
      variants={animationVariants}
      initial="initial"
      animate="in"
      exit="out"
      className="flex flex-col items-center w-full"
      onSubmit={(e: FormEvent) => {
        e.preventDefault();
        // prevents double submission
        if (!isLoading) {
          onSubmit(false, false);
        }
      }}>
      <motion.div variants={animationVariants} className="w-full">
        <StyledSelect
          label="Select an option"
          dropdownItems={FixedLooseLitterTypes.looseLitterList}
          onChange={(e) =>
            updateServiceRequest({
              ...serviceRequest,
              looseLitterType: (e.target as HTMLInputElement).value,
            })
          }
        />
      </motion.div>
      <motion.div variants={animationVariants} className="w-full">
        <StyledInput
          label="Please describe the litter"
          onChange={(e) =>
            updateServiceRequest({
              ...serviceRequest,
              wasteDetails: {
                ...serviceRequest.wasteDetails,
                wasteDescription: (e.target as HTMLInputElement).value,
              },
            })
          }
        />
      </motion.div>
      <motion.div variants={animationVariants} className="w-full">
        <StyledInput
          label="What is the approximate volume of the waste?"
          onChange={(e) =>
            updateServiceRequest({
              ...serviceRequest,
              wasteDetails: {
                ...serviceRequest.wasteDetails,
                approxAmount: (e.target as HTMLInputElement).value,
              },
            })
          }
        />
      </motion.div>
      <motion.div variants={animationVariants} className="w-full">
        <StyledInput
          label="How long has the waste been in the area? (optional)"
          required={false}
          onChange={(e) =>
            updateServiceRequest({
              ...serviceRequest,
              wasteDetails: {
                ...serviceRequest.wasteDetails,
                duration: (e.target as HTMLInputElement).value,
              },
            })
          }
        />
      </motion.div>
      <motion.div variants={animationVariants} className="w-full">
        <StyledRadio
          id="hazardousItems"
          label="Does the dumped material include hazardous items? (e.g. broken glass, needles, etc.)"
          onChange={(e) => {
            updateServiceRequest({
              ...serviceRequest,
              wasteDetails: {
                ...serviceRequest.wasteDetails,
                hazardousItems: helpers.convertStrToBool(
                  (e.target as HTMLInputElement).value
                ),
              },
            });
          }}
        />
      </motion.div>
      <motion.div variants={animationVariants} className="w-full">
        <StyledRadio
          id="didWitness"
          label="Did you witness the litter being dropped?"
          onChange={(e) => {
            updateServiceRequest({
              ...serviceRequest,
              wasteDetails: {
                ...serviceRequest.wasteDetails,
                didWitness: helpers.convertStrToBool(
                  (e.target as HTMLInputElement).value
                ),
              },
            });
          }}
        />
      </motion.div>
      {serviceRequest.wasteDetails.didWitness === true && (
        <motion.div variants={animationVariants} className="w-full">
          <StyledInput
            label="Details of the dumping, e.g. number plate, make and/or colour of vehicle, brief description of the person (optional)"
            required={false}
            onChange={(e) =>
              updateServiceRequest({
                ...serviceRequest,
                wasteDetails: {
                  ...serviceRequest.wasteDetails,
                  whoDumped: (e.target as HTMLInputElement).value,
                },
              })
            }
          />
        </motion.div>
      )}
      <motion.div variants={animationVariants} className="w-full">
        <StyledTextArea
          label="Add any additional comments (optional)"
          required={false}
          onChange={(e) =>
            updateServiceRequest({
              ...serviceRequest,
              comments: (e.target as HTMLInputElement).value,
            })
          }
        />
      </motion.div>
      <motion.div variants={animationVariants} className="w-full">
        <StyledInput
          label="Enter your full name"
          onChange={(e) =>
            updateServiceRequest({
              ...serviceRequest,
              name: (e.target as HTMLInputElement).value,
            })
          }
        />
      </motion.div>
      <motion.div variants={animationVariants} className="w-full">
        <StyledInput
          label="Enter your email address"
          type="email"
          onChange={(e) =>
            updateServiceRequest({
              ...serviceRequest,
              emailAddress: (e.target as HTMLInputElement).value,
            })
          }
        />
      </motion.div>
      <motion.div variants={animationVariants} className="w-full">
        <StyledInput
          label="Enter your phone number"
          type="number"
          onChange={(e) =>
            updateServiceRequest({
              ...serviceRequest,
              phoneNo: (e.target as HTMLInputElement).value,
            })
          }
        />
      </motion.div>
      <motion.div variants={animationVariants} className="w-full z-50">
        <AddressAutocomplete
          label="Enter the approximate address"
          serviceRequest={serviceRequest}
          updateServiceRequest={updateServiceRequest}
          addressErrorMessage={addressErrorMessage}
          updateAddressErrorMessage={updateAddressErrorMessage}
          isValidAddress={isValidAddress}
          updateIsValidAddress={updateIsValidAddress}
        />
      </motion.div>
      <motion.div variants={animationVariants} className="w-full mt-4">
        <Map
          serviceRequest={serviceRequest}
          updateServiceRequest={updateServiceRequest}
        />
      </motion.div>
      <motion.div
        variants={animationVariants}
        className="mb-4 h-full w-full flex flex-col border border-gray-300 shadow-sm px-3 py-3 rounded text-sm bg-white">
        <p className="text-sm text-gray-800 mb-3">
          If you have photos or video footage to support this report, please
          email them to sustainability.waste@tauranga.govt.nz, quoting the
          reference number supplied on the next page after clicking Submit.
        </p>
        <p className="text-sm text-gray-800">
          In clicking ‘Submit’, I confirm I have read and agree to the{" "}
          <a
            href="https://cms.environz.co.nz/media/Website-Privacy-Statement.pdf"
            className="underline"
            target="_blank"
            rel="noreferrer">
            Privacy Statement
          </a>{" "}
          and{" "}
          <a
            href="https://cms.environz.co.nz/media/Website-App-Terms-of-Use.pdf"
            className="underline"
            target="_blank"
            rel="noreferrer">
            Website/App Terms of Use
          </a>
          .
        </p>
      </motion.div>
      {formErrorMessage && (
        <motion.div
          variants={animationVariants}
          className="mb-4 bg-white rounded w-full">
          <div className="m-2 p-2 relative border border-red-500 rounded flex items-center justify-end">
            <p className="mr-0 flex-grow text-red-500 ">{formErrorMessage}</p>
            <div className="flex-shrink-0 mr-2">
              <ExclamationMark />
            </div>
          </div>
        </motion.div>
      )}
      <motion.div
        variants={animationVariants}
        className="w-full flex justify-end">
        <button
          type="submit"
          disabled={addressErrorMessage !== null}
          className="body-font disabled-button-style w-28 xl:w-40 text-white bg-tcc-green transition duration-300 ease-in-out hover:bg-tcc-green-lighter rounded py-2.5 md:py-3 focus:outline-none border border-transparent focus:border-gray-800">
          {isLoading ? <DotSpinner /> : "Submit"}
        </button>
      </motion.div>
    </motion.form>
  );
}

export default LooseLitterPath;
