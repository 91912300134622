import { useState } from "react";
import { FixedServiceRequestTypes, FixedServiceTypes } from "../../core/constants/constants";
import { IConfirmationModal } from "../../core/types/types";

function ConfirmationModal({
  isModalOpen,
  setIsModalOpen,
  formatedAddress,
  services,
  onSubmit,
  serviceRequest,
  frequency,
  binBundle,
}: IConfirmationModal) {
  const [checked, setChecked] = useState<boolean>(false);

  const handleOnClose = () => {
    setChecked(false);
    setIsModalOpen(false);
  };

  const handleOnSubmit = () => {
    setChecked(false);
    setIsModalOpen(false);
    onSubmit(true, false);
  };

  // If open state is set to false don't display modal
  return !isModalOpen ? (
    <div></div>
  ) : (
    <>
      <div
        className="fixed top-0 left-0 right-0 bottom-0 bg-black opacity-70 z-40"
        onClick={handleOnClose}
      />
      <div className="fixed top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 sm:px-8 px-4 sm:pb-6 pb-3 sm:pt-6 pt-3 z-50 border bg-white lg:w-1/2 w-10/12">
        <h3 className="flex items-center justify-center pb-6">
          <p className="text-blue-600 text-base sm:text-lg font-bold">
            Confirm your service request
          </p>
        </h3>
        <p className="pb-1 text-sm sm:text-base text-gray-500">
          <span className="font-bold text-black">Collection address:</span> {formatedAddress}
        </p>
        {serviceRequest === FixedServiceRequestTypes.ADDITIONAL_BIN && (
          <>
            <p className="text-sm sm:text-base inline-block">
              <span className="font-bold">Cost per service per year:</span>{" "}
              {services?.map((service: string, index: any) => {
                // match the service
                let price = FixedServiceTypes.additionalBinServiceList[service] ?? "Price not found"; // prettier-ignore
                // add commas to all but last item
                return index < services.length - 1 ? (
                  <span key={index} className="text-gray-500 inline-block pr-2">
                    {`${price},`}
                  </span>
                ) : (
                  <span key={index} className="text-gray-500 inline-block">
                    {price}
                  </span>
                );
              })}
            </p>
            <p className="text-gray-500 pt-4 pb-6 text-sm sm:text-base">
              *if you order additional bins in the second half of the rates year (after 1 Jan) you
              will pay half of this price. If you’re ordering close to the end of the rates year
              (between April and June) you won’t be charged for the remainder of that rates year.{" "}
            </p>
          </>
        )}
        {(serviceRequest === FixedServiceRequestTypes.GARDEN_WASTE_NEW ||
          serviceRequest === FixedServiceRequestTypes.GARDEN_WASTE_CHANGE) && (
          <>
            <p className="text-sm sm:text-base pb-1">
              <b>Size of bin:</b> 240L
            </p>
            <p className="text-sm sm:text-base">
              <b>Cost per frequency per year:</b>{" "}
              {frequency === "Fortnightly" ? "$110 for the year" : "$80 for the year"}
            </p>
            <p className="text-gray-500 pt-4 text-sm sm:text-base">
              *if you’re signing up in the second half of the rates year (after 1 Jan) you will pay
              half of this price. If you’re signing up close to the end of the rates year (between
              April and June) you won’t be charged for the remainder of that rates year.
            </p>
            <p className="text-gray-500 pt-4 pb-6 text-sm sm:text-base">
              *If you changed your garden waste collection frequency, the cost you have selected
              will apply to the following rates year
            </p>
          </>
        )}
        {serviceRequest === FixedServiceRequestTypes.CHANGE_BIN_SIZES && (
          <>
            <p className="text-sm sm:text-base">
              <span className="font-bold">Bin bundle selected: </span>
              <span className="text-gray-500">{binBundle}</span>
            </p>
            <p className="text-gray-500 pt-4 pb-6 text-sm sm:text-base">
              *The cost for this service is included as a targeted rate on your property’s rates
              invoice and will be updated to reflect this change from 1 July.
            </p>
          </>
        )}
        {serviceRequest === FixedServiceRequestTypes.NEW_PROPERTY_BIN && (
          <>
            <p className="text-sm sm:text-base pb-1">
              Please note the charge for this service will apply from the next rating year on 1 July.
              Below are the annual costs for the different bin bundles for the period from 1 July 2024
              to 30 June 2025:
            </p>
            <p className="pt-1 text-sm sm:text-base">
              Low waste bin bundle – $210
            </p>
            <p className="pt-1 text-sm sm:text-base">
              Standard bin bundle - $245
            </p>
            <p className="pt-1 pb-6 text-sm sm:text-base">
              High waste bin bundle – $350
            </p>
          </>
        )}
        <label className="flex justify-end cursor-pointer text-sm sm:text-base pb-6 pr-4">
          <b>Please tick to confirm the above</b>
          <input
            type="checkbox"
            onChange={() => setChecked(!checked)}
            className="sm:ml-8 ml-4 mt-1 cursor-pointer sm:w-5 sm:h-5 w-4 h-4"
          />
        </label>
        <div className="flex float-right pr-4">
          <h1 className="text-red-500 pr-10 cursor-pointer" onClick={handleOnClose}>
            CANCEL
          </h1>
          {checked ? (
            <h1 className="text-green-500 cursor-pointer" onClick={handleOnSubmit}>
              SUBMIT
            </h1>
          ) : (
            <h1 className="text-gray-500 cursor-pointer">SUBMIT</h1>
          )}
        </div>
      </div>
    </>
  );
};

export default ConfirmationModal;
