import { IStyledInput } from "../../core/types/types";

function StyledInput({
  type = "text",
  label,
  required = true,
  onChange,
  value,
  margin = "mb-4",
}: IStyledInput) {
  return (
    <div className={`${margin} h-full w-full flex flex-col`}>
      {/* <label htmlFor={label} className="subheading-font pb-2">
        {label}
      </label> */}
      <input
        type={type}
        id={label}
        name={label}
        placeholder={label}
        onWheel={() => (document.activeElement as HTMLElement).blur()}
        onChange={onChange}
        required={required}
        title={label}
        value={value}
        className="border border-gray-300 shadow-sm pl-3 py-3 transition duration-300 ease-in-out rounded text-base focus:outline-none focus:border-gray-800 bg-white placeholder-gray-500 text-gray-800"
      />
    </div>
  );
};

export default StyledInput;
