import axios from "axios";
import { envConfig } from "../environment/envConfig";
import { IServiceRequestForAPI } from "../types/types";

axios.defaults.baseURL = envConfig.apiBaseUrl;

// Creates a service request in the database and returns a ticket number to the user
async function createServiceRequest(
  serviceRequest: IServiceRequestForAPI,
  bypassAddressError: boolean
) {
  try {
    const env = process.env.REACT_APP_ENVIRONMENT === "prod" ? "prod" : "dev";
    const queryParams = {
      env: env,
      code: envConfig.keys.createServiceRequestKey,
      bypassAddressError: bypassAddressError.toString(),
    };
    const response = await axios.post("tcc/createservicerequest", serviceRequest, {
      params: queryParams,
    });
    return response;
  } catch (error: any) {
    // error.response has access to status and data set in API
    return error.response;
  }
};

async function checkAddress(
  address: string,
  postCode: string | null,
  assessmentNumber: string | null,
  customerNumber: string | null
) {
  try {
    const env = process.env.REACT_APP_ENVIRONMENT === "prod" ? "prod" : "dev";
    const queryParams = {
      env: env,
      code: envConfig.keys.checkAddressKey,
      street: address,
      postcode: postCode,
      assessmentNo: assessmentNumber,
      customerId: customerNumber,
    };
    const response = await axios.get("tcc/checkaddress", {
      params: queryParams,
    });
    return response;
  } catch (error: any) {
    return error.response;
  }
};

export const apiService = {
  createServiceRequest,
  checkAddress,
};
