import blank_ratepayer_form from "../../images/blank_ratepayer_form.jpg";

interface Props {
  open: boolean;
  onClose: any;
}

function HelperModal ({ open, onClose }: Props) {
  // If open state is set to false don't display modal
  if (!open) return null;

  return (
    <>
      <div
        className="fixed top-0 left-0 right-0 bottom-0 bg-black opacity-70 z-40"
        onClick={onClose}
      />
      <div className="fixed top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 px-1 pb-3 pt-1 z-50 border bg-white lg:w-1/2 w-10/12">
        <div className="float-right cursor-pointer pb-2" onClick={onClose}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="sm:h-6 sm:w-6 h-4 w-4"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </div>
        <img src={blank_ratepayer_form} alt="Blank ratepayer form" />
      </div>
    </>
  );
};

export default HelperModal;
