import PublicLitterBinsPath from "../paths/PublicLitterBins/PublicLittersBinsPath";
import StandardServicePath from "../paths/StandardServicePath/StandardServicePath";
import IllegalDumpingPath from "../paths/IllegalDumpingPath/IllegalDumpingPath";
import LooseLitterPath from "../paths/LooseLitterPath/LooseLitterPath";
import StyledSelect from "../components/StyledInputs/StyledSelect";
import ErrorModal from "../components/Modals/ErrorModal";
import { IAddressComponents, IHome, IServiceRequestForAPI } from "../core/types/types";
import { FixedServiceRequestTypes } from "../core/constants/constants";
import { apiService } from "../core/services/apiService";
import { AnimatePresence } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { AxiosResponse } from "axios";
import { useState } from "react";

function Home({
  initialState,
  serviceRequest,
  updateServiceRequest,
  updateHasCompletedForm,
}: IHome) {
  const navigate = useNavigate();

  // state for custom validation errors
  const [formErrorMessage, updateFormErrorMessage] = useState<string | null>(null);
  const [checkboxErrorMessage, updateCheckboxErrorMessage] = useState<string | null>(null);
  const [customerNoError, updateCustomerNoError] = useState<string | null>(null);
  const [assessmentNoError, updateAssessmentNoError] = useState<string | null>(null);
  const [wasteDescError, updateWasteDescError] = useState<string | null>(null);
  const errors = {
    checkboxError: "You need to select a service from the checkboxes above.",
    addressValidationError:
      "The details you have entered are either incorrect or your property is not yet recognised in the system e.g. because your property is new. " +
      "Please try to enter your details again and if it still doesn't work, please call 07 577 7000 or email us at kerbside@tauranga.govt.nz for help.",
    invalidAssessmentNo: "Please enter a valid assessment number",
    invalidCustomerNo: "Please enter a valid customer number",
    specialCharValidationError: "Please remove all specials characters from this field",
    apiError: "Something went wrong with this request. Please try again later or contact us.",
  };

  // state for manual entry of addresses
  const [manualAddress, updateManualAddress] = useState(false);
  const [backupStreetNo, updateBackupStreetNo] = useState("");
  const [backupStreetName, updateBackupStreetName] = useState("");
  const [backupPostCode, updateBackupPostCode] = useState("");

  // state for waste details when 'other' is selected in multi-select
  const [otherWasteDetails, updateOtherWasteDetails] = useState("");

  // loading state
  const [isLoading, updateIsLoading] = useState(false);

  // Error modal state
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState<boolean>(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  // SUBMIT HANDLER FOR ALL SERVICE REQUEST TYPES
  async function onSubmit(overrideConfirmation: boolean, overrideError: boolean) {
    updateFormErrorMessage(null);
    updateIsLoading(true);
    // validate custom form components
    if (customValidation()) {
      // if confirmation has not been given for service requests that require it, show confirmation modal
      if (
        (serviceRequest.serviceRequest === FixedServiceRequestTypes.ADDITIONAL_BIN ||
          serviceRequest.serviceRequest === FixedServiceRequestTypes.CHANGE_BIN_SIZES ||
          serviceRequest.serviceRequest === FixedServiceRequestTypes.GARDEN_WASTE_NEW ||
          serviceRequest.serviceRequest === FixedServiceRequestTypes.GARDEN_WASTE_CHANGE ||
          serviceRequest.serviceRequest === FixedServiceRequestTypes.CANCEL_GARDEN_WASTE ||
          serviceRequest.serviceRequest === FixedServiceRequestTypes.NEW_PROPERTY_BIN) &&
        !overrideConfirmation
      ) {
        updateIsLoading(false);
        setIsConfirmationModalOpen(true);
      } else if (
        // validate address details unless it's one of these service requests that don't require it
        serviceRequest.serviceRequest === FixedServiceRequestTypes.ILLEGAL_DUMPING ||
        serviceRequest.serviceRequest === FixedServiceRequestTypes.PUBLIC_LITTER_BINS ||
        serviceRequest.serviceRequest === FixedServiceRequestTypes.LOOSE_LITTER ||
        (await validateResidentOrRatepayer())
      ) {
        // create new service request object with stringified service array for API
        const servicerequestForApi: IServiceRequestForAPI = Object.assign({}, serviceRequest, {
          service: serviceRequest.service === null ? null : serviceRequest.service.toString(),
        });
        // append "otherWasteDetails" if "Other" has been selected for illegal dumping SR
        if (serviceRequest.wasteDetails.wasteDescription?.includes("Other")) {
          servicerequestForApi.wasteDetails.wasteDescription =
            serviceRequest.wasteDetails.wasteDescription + `, ${otherWasteDetails}`;
        }
        // post service request to API
        const response = await apiService.createServiceRequest(servicerequestForApi, overrideError);
        handleResponse(response, overrideError);
      }
    }
    updateIsLoading(false);
  }

  // RESPONSE HANDLER FOR ALL SUBMIT METHODS
  function handleResponse(response: AxiosResponse, overrideError: boolean) {
    // set ticket number and navigate to confirmation page on success
    if (response?.status === 200 && parseInt(response.data)) {
      serviceRequest.ticketNumber = parseInt(response.data);
      updateHasCompletedForm(true);
      updateIsLoading(false);
      if (overrideError) setIsErrorModalOpen(false);
      navigate("/confirmation");
      // show error modal with API response (this has the option to continue) for 900 errors
    } else if (response?.status === 900) {
      updateIsLoading(false);
      setErrorMessage(response.data);
      setIsErrorModalOpen(true);
      // show error message from API for 999, otherwise show generaic API error
    } else {
      updateIsLoading(false);
      updateFormErrorMessage(response?.status === 999 ? response.data : errors.apiError);
    }
  }

  // VALIDATE EITHER AN ADDRESS OR AN ADDRESS AND RATES VALUATION NUMBER WITH TCC KERBSIDE DATA
  async function validateResidentOrRatepayer(): Promise<boolean> {
    const { StreetNumber, RoadName, PostCode } = serviceRequest.addressComponents;

    let newPostCode = PostCode;
    let address = `${StreetNumber} ${RoadName}`;
    const assessmentNumber = serviceRequest.assessmentNumber;
    const customerNumber = serviceRequest.customerNumber;

    // use backup address components if manual address mode is on
    if (manualAddress) {
      address = backupStreetNo + " " + backupStreetName;
      newPostCode = backupPostCode;
    }

    const response = await apiService.checkAddress(
      address,
      newPostCode,
      assessmentNumber,
      customerNumber
    );

    if (response?.status === 200) {
      // hydrate address components if address was entered manually
      if (manualAddress && isAddressComponents(response.data)) {
        serviceRequest.addressComponents = response.data;
      }
      return true;
    } else if (response?.status === 400 && response?.data === "Failed") {
      updateFormErrorMessage(errors.addressValidationError);
      return false;
    }
    updateFormErrorMessage(errors.apiError);
    return false;
  }

  // CUSTOM FORM VALIDATION FOR ALL SERVICE REQUEST TYPES
  function customValidation(): boolean {
    let isValid = true;
    // validate service checkboxes when they are present
    if (
      (serviceRequest.serviceRequest === FixedServiceRequestTypes.DAMAGED_BIN ||
        serviceRequest.serviceRequest === FixedServiceRequestTypes.MISSING_BIN ||
        serviceRequest.serviceRequest === FixedServiceRequestTypes.ADDITIONAL_BIN ||
        serviceRequest.serviceRequest === FixedServiceRequestTypes.MISSED_COLLECTION) &&
      (serviceRequest.service === null || !serviceRequest.service.length)
    ) {
      isValid = false;
      updateCheckboxErrorMessage(errors.checkboxError);
    }
    if (
      serviceRequest.serviceRequest === FixedServiceRequestTypes.ADDITIONAL_BIN ||
      serviceRequest.serviceRequest === FixedServiceRequestTypes.CHANGE_BIN_SIZES ||
      serviceRequest.serviceRequest === FixedServiceRequestTypes.GARDEN_WASTE_NEW ||
      serviceRequest.serviceRequest === FixedServiceRequestTypes.GARDEN_WASTE_CHANGE ||
      serviceRequest.serviceRequest === FixedServiceRequestTypes.CANCEL_GARDEN_WASTE
    ) {
      if (
        serviceRequest.assessmentNumber != null &&
        RegExp(/[^A-Za-z0-9]/g).test(serviceRequest.assessmentNumber)
      ) {
        isValid = false;
        updateAssessmentNoError(errors.specialCharValidationError);
      }
      if (
        serviceRequest.customerNumber != null &&
        RegExp(/[^A-Za-z0-9]/g).test(serviceRequest.customerNumber)
      ) {
        isValid = false;
        updateCustomerNoError(errors.specialCharValidationError);
      }
      if (
        serviceRequest.assessmentNumber === null ||
        serviceRequest.assessmentNumber.length === 0
      ) {
        isValid = false;
        updateAssessmentNoError(errors.invalidAssessmentNo);
      }
      if (
        serviceRequest.customerNumber === null ||
        serviceRequest.customerNumber.length === 0 ||
        parseInt(serviceRequest.customerNumber) + 1 === 1
      ) {
        isValid = false;
        updateCustomerNoError(errors.invalidCustomerNo);
      }
    }

    if (
      serviceRequest.serviceRequest === FixedServiceRequestTypes.ILLEGAL_DUMPING &&
      (serviceRequest.wasteDetails.wasteDescription === null ||
        serviceRequest.wasteDetails.wasteDescription === "")
    ) {
      updateWasteDescError("You need to select an option above");
      isValid = false;
    }
    return isValid;
  }

  // TYPE GUARD CHECK TO ENSURE CHECKADDRESS API RESPONSE IS OF TYPE IADDRESSCOMPONENTS
  function isAddressComponents(object: any): object is IAddressComponents {
    return (object as IAddressComponents).FormattedAddress !== undefined;
  }

  return (
    <div className="mt-10 mb-40 w-11/12 md:w-3/4 xl:7/12">
      <div className="py-12 my-auto mx-auto flex flex-col items-start justify-start xl:w-4/5 2xl:w-7/12">
        <h1 className="heading-font w-full text-left mb-6">How can we help you?</h1>
        {/******** SERVICE REQUEST DROPDOWN ********/}
        <StyledSelect
          label={"Please select your service request"}
          serviceRequestDropdowns={FixedServiceRequestTypes.serviceRequestList}
          defaultValue={serviceRequest.serviceRequest ?? ""}
          onChange={(e) => {
            // when path is change, reset service request
            // and all custom validation & loading states
            updateIsLoading(false);
            updateFormErrorMessage(null);
            updateCheckboxErrorMessage(null);
            updateAssessmentNoError(null);
            updateCustomerNoError(null);
            updateWasteDescError(null);
            updateManualAddress(false);
            updateBackupStreetNo("");
            updateBackupStreetName("");
            updateBackupPostCode("");
            updateServiceRequest({
              ...initialState,
              serviceRequest: (e.target as HTMLInputElement).value,
            });
          }}
        />
        {/******** PATH FOR DAMAGED BIN SERVICE REQUESTS ********/}
        <AnimatePresence>
          {serviceRequest?.serviceRequest === FixedServiceRequestTypes.DAMAGED_BIN && (
            <StandardServicePath
              key={1}
              serviceRequest={serviceRequest}
              updateServiceRequest={updateServiceRequest}
              onSubmit={onSubmit}
              checkboxErrorMessage={checkboxErrorMessage}
              updateCheckboxErrorMessage={updateCheckboxErrorMessage}
              isLoading={isLoading}
              formErrorMessage={formErrorMessage}
              assessmentNoError={assessmentNoError}
              updateAssessmentNoError={updateAssessmentNoError}
              customerNoError={customerNoError}
              updateCustomerNoError={updateCustomerNoError}
              backupStreetNo={backupStreetNo}
              backupStreetName={backupStreetName}
              updateBackupStreetNo={updateBackupStreetNo}
              updateBackupPostCode={updateBackupPostCode}
              updateBackupStreetName={updateBackupStreetName}
              manualAddress={manualAddress}
              updateManualAddress={updateManualAddress}
              isConfirmationModalOpen={isConfirmationModalOpen}
              setIsConfirmationModalOpen={setIsConfirmationModalOpen}
            />
          )}
          {/******** PATH FOR MISSING BIN SERVICE REQUESTS ********/}
          {serviceRequest?.serviceRequest === FixedServiceRequestTypes.MISSING_BIN && (
            <StandardServicePath
              key={2}
              serviceRequest={serviceRequest}
              updateServiceRequest={updateServiceRequest}
              onSubmit={onSubmit}
              checkboxErrorMessage={checkboxErrorMessage}
              updateCheckboxErrorMessage={updateCheckboxErrorMessage}
              isLoading={isLoading}
              formErrorMessage={formErrorMessage}
              assessmentNoError={assessmentNoError}
              updateAssessmentNoError={updateAssessmentNoError}
              customerNoError={customerNoError}
              updateCustomerNoError={updateCustomerNoError}
              backupStreetNo={backupStreetNo}
              backupStreetName={backupStreetName}
              updateBackupStreetNo={updateBackupStreetNo}
              updateBackupPostCode={updateBackupPostCode}
              updateBackupStreetName={updateBackupStreetName}
              manualAddress={manualAddress}
              updateManualAddress={updateManualAddress}
              isConfirmationModalOpen={isConfirmationModalOpen}
              setIsConfirmationModalOpen={setIsConfirmationModalOpen}
            />
          )}
          {/******** PATH FOR ADDITIONAL BIN SERVICE REQUESTS ********/}
          {serviceRequest?.serviceRequest === FixedServiceRequestTypes.ADDITIONAL_BIN && (
            <StandardServicePath
              key={3}
              serviceRequest={serviceRequest}
              updateServiceRequest={updateServiceRequest}
              onSubmit={onSubmit}
              checkboxErrorMessage={checkboxErrorMessage}
              updateCheckboxErrorMessage={updateCheckboxErrorMessage}
              isLoading={isLoading}
              formErrorMessage={formErrorMessage}
              assessmentNoError={assessmentNoError}
              updateAssessmentNoError={updateAssessmentNoError}
              customerNoError={customerNoError}
              updateCustomerNoError={updateCustomerNoError}
              backupStreetNo={backupStreetNo}
              backupStreetName={backupStreetName}
              updateBackupStreetNo={updateBackupStreetNo}
              updateBackupPostCode={updateBackupPostCode}
              updateBackupStreetName={updateBackupStreetName}
              manualAddress={manualAddress}
              updateManualAddress={updateManualAddress}
              isConfirmationModalOpen={isConfirmationModalOpen}
              setIsConfirmationModalOpen={setIsConfirmationModalOpen}
            />
          )}
          {/******** PATH FOR GARDEN WASTE SERVICE REQUESTS ********/}
          {(serviceRequest?.serviceRequest === FixedServiceRequestTypes.GARDEN_WASTE_NEW ||
            serviceRequest?.serviceRequest === FixedServiceRequestTypes.GARDEN_WASTE_CHANGE ||
            serviceRequest?.serviceRequest === FixedServiceRequestTypes.CANCEL_GARDEN_WASTE) && (
            <StandardServicePath
              key={4}
              serviceRequest={serviceRequest}
              updateServiceRequest={updateServiceRequest}
              onSubmit={onSubmit}
              checkboxErrorMessage={checkboxErrorMessage}
              updateCheckboxErrorMessage={updateCheckboxErrorMessage}
              isLoading={isLoading}
              formErrorMessage={formErrorMessage}
              assessmentNoError={assessmentNoError}
              updateAssessmentNoError={updateAssessmentNoError}
              customerNoError={customerNoError}
              updateCustomerNoError={updateCustomerNoError}
              backupStreetNo={backupStreetNo}
              backupStreetName={backupStreetName}
              updateBackupStreetNo={updateBackupStreetNo}
              updateBackupPostCode={updateBackupPostCode}
              updateBackupStreetName={updateBackupStreetName}
              manualAddress={manualAddress}
              updateManualAddress={updateManualAddress}
              isConfirmationModalOpen={isConfirmationModalOpen}
              setIsConfirmationModalOpen={setIsConfirmationModalOpen}
            />
          )}
          {/******** PATH FOR NEW PROPERTY BIN SERVICE REQUESTS ********/}
          {serviceRequest?.serviceRequest === FixedServiceRequestTypes.NEW_PROPERTY_BIN && (
            <StandardServicePath
              key={5}
              serviceRequest={serviceRequest}
              updateServiceRequest={updateServiceRequest}
              onSubmit={onSubmit}
              checkboxErrorMessage={checkboxErrorMessage}
              updateCheckboxErrorMessage={updateCheckboxErrorMessage}
              isLoading={isLoading}
              formErrorMessage={formErrorMessage}
              assessmentNoError={assessmentNoError}
              updateAssessmentNoError={updateAssessmentNoError}
              customerNoError={customerNoError}
              updateCustomerNoError={updateCustomerNoError}
              backupStreetNo={backupStreetNo}
              backupStreetName={backupStreetName}
              updateBackupStreetNo={updateBackupStreetNo}
              updateBackupPostCode={updateBackupPostCode}
              updateBackupStreetName={updateBackupStreetName}
              manualAddress={manualAddress}
              updateManualAddress={updateManualAddress}
              isConfirmationModalOpen={isConfirmationModalOpen}
              setIsConfirmationModalOpen={setIsConfirmationModalOpen}
            />
          )}
          {/******** PATH FOR CHANGE BIN SIZE SERVICE REQUESTS ********/}
          {serviceRequest?.serviceRequest === FixedServiceRequestTypes.CHANGE_BIN_SIZES && (
            <StandardServicePath
              key={6}
              serviceRequest={serviceRequest}
              updateServiceRequest={updateServiceRequest}
              onSubmit={onSubmit}
              checkboxErrorMessage={checkboxErrorMessage}
              updateCheckboxErrorMessage={updateCheckboxErrorMessage}
              isLoading={isLoading}
              formErrorMessage={formErrorMessage}
              assessmentNoError={assessmentNoError}
              updateAssessmentNoError={updateAssessmentNoError}
              customerNoError={customerNoError}
              updateCustomerNoError={updateCustomerNoError}
              backupStreetNo={backupStreetNo}
              backupStreetName={backupStreetName}
              updateBackupStreetNo={updateBackupStreetNo}
              updateBackupPostCode={updateBackupPostCode}
              updateBackupStreetName={updateBackupStreetName}
              manualAddress={manualAddress}
              updateManualAddress={updateManualAddress}
              isConfirmationModalOpen={isConfirmationModalOpen}
              setIsConfirmationModalOpen={setIsConfirmationModalOpen}
            />
          )}
          {/******** PATH FOR MISSED COLLECTION SERVICE REQUESTS ********/}
          {serviceRequest?.serviceRequest === FixedServiceRequestTypes.MISSED_COLLECTION && (
            <StandardServicePath
              key={7}
              serviceRequest={serviceRequest}
              updateServiceRequest={updateServiceRequest}
              onSubmit={onSubmit}
              checkboxErrorMessage={checkboxErrorMessage}
              updateCheckboxErrorMessage={updateCheckboxErrorMessage}
              isLoading={isLoading}
              formErrorMessage={formErrorMessage}
              assessmentNoError={assessmentNoError}
              updateAssessmentNoError={updateAssessmentNoError}
              customerNoError={customerNoError}
              updateCustomerNoError={updateCustomerNoError}
              backupStreetNo={backupStreetNo}
              backupStreetName={backupStreetName}
              updateBackupStreetNo={updateBackupStreetNo}
              updateBackupPostCode={updateBackupPostCode}
              updateBackupStreetName={updateBackupStreetName}
              manualAddress={manualAddress}
              updateManualAddress={updateManualAddress}
              isConfirmationModalOpen={isConfirmationModalOpen}
              setIsConfirmationModalOpen={setIsConfirmationModalOpen}
            />
          )}
          {/******** PATH FOR ILLEGAL DUMPING SERVICE REQUESTS ********/}
          {serviceRequest?.serviceRequest === FixedServiceRequestTypes.ILLEGAL_DUMPING && (
            <IllegalDumpingPath
              key={8}
              serviceRequest={serviceRequest}
              updateServiceRequest={updateServiceRequest}
              onSubmit={onSubmit}
              isLoading={isLoading}
              formErrorMessage={formErrorMessage}
              updateOtherWasteDetails={updateOtherWasteDetails}
              wasteDescError={wasteDescError}
              updateWasteDescError={updateWasteDescError}
            />
          )}
          {/******** PATH FOR PUBLIC LITTER BIN SERVICE REQUESTS ********/}
          {serviceRequest?.serviceRequest === FixedServiceRequestTypes.PUBLIC_LITTER_BINS && (
            <PublicLitterBinsPath
              key={9}
              serviceRequest={serviceRequest}
              updateServiceRequest={updateServiceRequest}
              onSubmit={onSubmit}
              isLoading={isLoading}
              formErrorMessage={formErrorMessage}
            />
          )}
          {/******** PATH FOR LOOSE LITTER SERVICE REQUESTS ********/}
          {serviceRequest?.serviceRequest === FixedServiceRequestTypes.LOOSE_LITTER && (
            <LooseLitterPath
              key={10}
              serviceRequest={serviceRequest}
              updateServiceRequest={updateServiceRequest}
              onSubmit={onSubmit}
              isLoading={isLoading}
              formErrorMessage={formErrorMessage}
            />
          )}
        </AnimatePresence>
        <ErrorModal
          isLoading={isLoading}
          isModalOpen={isErrorModalOpen}
          setIsModalOpen={setIsErrorModalOpen}
          errorMessage={errorMessage}
          onSubmit={onSubmit}
        />
      </div>
    </div>
  );
}

export default Home;
