import AddressAutocomplete from "../../components/AddressAutocomplete/AddressAutocomplete";
import ExclamationMark from "../../components/ExclamationMark/ExclamationMark";
import StyledTextArea from "../../components/StyledInputs/StyledTextArea";
import StyledSelect from "../../components/StyledInputs/StyledSelect";
import StyledInput from "../../components/StyledInputs/StyledInput";
import DotSpinner from "../../components/DotSpinner/DotSpinner";
import Map from "../../components/Map/Map";
import {
  animationVariants,
  FixedPublicLitterTypes,
} from "../../core/constants/constants";
import { IPublicLitterBinsPath } from "../../core/types/types";
import { motion } from "framer-motion";
import { FormEvent, useState } from "react";

function PublicLitterBinsPath({
  serviceRequest,
  updateServiceRequest,
  onSubmit,
  isLoading,
  formErrorMessage,
}: IPublicLitterBinsPath) {
  const [addressErrorMessage, updateAddressErrorMessage] = useState<
    string | null
  >(null);
  const [isValidAddress, updateIsValidAddress] = useState(false);

  return (
    <motion.form
      variants={animationVariants}
      initial="initial"
      animate="in"
      exit="out"
      className="flex flex-col items-center w-full"
      onSubmit={(e: FormEvent) => {
        e.preventDefault();
        // prevents double submission
        if (!isLoading) {
          onSubmit(false, false);
        }
      }}>
      <motion.div variants={animationVariants} className="w-full">
        <StyledSelect
          label="Select from the following"
          dropdownItems={FixedPublicLitterTypes.publicLitterBinsList}
          onChange={(e) =>
            updateServiceRequest({
              ...serviceRequest,
              publicLitterType: (e.target as HTMLInputElement).value,
            })
          }
        />
      </motion.div>
      <motion.div
        key="not-damaged-public-bin"
        variants={animationVariants}
        className="w-full">
        <motion.div variants={animationVariants} className="w-full">
          <StyledTextArea
            label={
              FixedPublicLitterTypes.DAMAGED_PUBLIC_BINS ===
              serviceRequest.publicLitterType
                ? "Please describe the damage or vandalism"
                : "Add any additional comments (optional)"
            }
            required={true}
            onChange={(e) =>
              updateServiceRequest({
                ...serviceRequest,
                comments: (e.target as HTMLInputElement).value,
              })
            }
          />
        </motion.div>
        <motion.div variants={animationVariants} className="w-full">
          <StyledInput
            label="Enter your full name"
            onChange={(e) =>
              updateServiceRequest({
                ...serviceRequest,
                name: (e.target as HTMLInputElement).value,
              })
            }
          />
        </motion.div>
        <motion.div variants={animationVariants} className="w-full">
          <StyledInput
            label="Enter your email address"
            type="email"
            onChange={(e) =>
              updateServiceRequest({
                ...serviceRequest,
                emailAddress: (e.target as HTMLInputElement).value,
              })
            }
          />
        </motion.div>
        <motion.div variants={animationVariants} className="w-full">
          <StyledInput
            label="Enter your phone number"
            type="number"
            onChange={(e) =>
              updateServiceRequest({
                ...serviceRequest,
                phoneNo: (e.target as HTMLInputElement).value,
              })
            }
          />
        </motion.div>
        <motion.div variants={animationVariants} className="w-full z-50">
          <AddressAutocomplete
            label="Enter the approximate address"
            serviceRequest={serviceRequest}
            updateServiceRequest={updateServiceRequest}
            addressErrorMessage={addressErrorMessage}
            updateAddressErrorMessage={updateAddressErrorMessage}
            isValidAddress={isValidAddress}
            updateIsValidAddress={updateIsValidAddress}
          />
        </motion.div>
        <motion.div variants={animationVariants} className="w-full mt-4">
          <Map
            serviceRequest={serviceRequest}
            updateServiceRequest={updateServiceRequest}
          />
        </motion.div>
      </motion.div>
      <motion.div
        variants={animationVariants}
        className="mb-4 h-full w-full flex flex-col">
        <p className="border border-gray-300 shadow-sm px-3 py-3 rounded bg-white text-sm text-gray-800">
          In clicking ‘Submit’, I confirm I have read and agree to the{" "}
          <a
            href="https://cms.environz.co.nz/media/Website-Privacy-Statement.pdf"
            className="underline"
            target="_blank"
            rel="noreferrer">
            Privacy Statement
          </a>{" "}
          and{" "}
          <a
            href="https://cms.environz.co.nz/media/Website-App-Terms-of-Use.pdf"
            className="underline"
            target="_blank"
            rel="noreferrer">
            Website/App Terms of Use
          </a>
          .
        </p>
      </motion.div>
      {formErrorMessage && (
        <motion.div
          variants={animationVariants}
          className="mb-4 bg-white rounded w-full">
          <div className="m-2 p-2 relative border border-red-500 rounded flex items-center justify-end">
            <p className="mr-0 flex-grow text-red-500 ">{formErrorMessage}</p>
            <div className="flex-shrink-0 mr-2">
              <ExclamationMark />
            </div>
          </div>
        </motion.div>
      )}
      <motion.div
        variants={animationVariants}
        className="w-full flex justify-end">
        <button
          type="submit"
          disabled={addressErrorMessage !== null}
          className="body-font disabled-button-style w-28 xl:w-40 text-white bg-tcc-green transition duration-300 ease-in-out hover:bg-tcc-green-lighter rounded py-2.5 md:py-3 focus:outline-none border border-transparent focus:border-gray-800">
          {isLoading ? <DotSpinner /> : "Submit"}
        </button>
      </motion.div>
    </motion.form>
  );
}

export default PublicLitterBinsPath;
