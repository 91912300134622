function Footer() {
  return (
    <div className="bg-tcc-grey w-full py-20 sm:py-12 relative">
      <div className="w-11/12 md:w-8/12 mx-auto flex flex-col-reverse sm:flex-row items-center sm:items-end justify-center sm:justify-between">
        <div className="flex flex-col items-start justify-start">
          <a
            href="https://cms.environz.co.nz/media/Website-Privacy-Statement.pdf"
            target="_blank"
            rel="noreferrer">
            <p className="body-font text-white">Privacy Statement </p>
          </a>
          <a
            href="https://cms.environz.co.nz/media/Website-Terms-of-Use-Kerbside-Portal.pdf"
            target="_blank"
            rel="noreferrer">
            <p className="body-font text-white">Website terms of use</p>
          </a>
        </div>
        <div className="flex flex-col items-start mb-10 sm:mb-0">
          <p className="confirmation-font mb-4 text-white">Need help?</p>
          <a href="tel:+6475777000">
            <p className="body-font text-white">+64 7 577 7000</p>
          </a>
          <a href="mailto:kerbside@tauranga.govt.nz">
            <p className="body-font text-white">kerbside@tauranga.govt.nz</p>
          </a>
        </div>
      </div>
      <p className="absolute bottom-1 left-1 text-xs text-gray-600">v1.0.16</p>
    </div>
  );
}

export default Footer;
