import DotSpinner from "../DotSpinner/DotSpinner";
import { IErrorModal } from "../../core/types/types";

function ErrorModal({
  isLoading,
  isModalOpen,
  setIsModalOpen,
  errorMessage,
  onSubmit,
}: IErrorModal) {
  function handleSubmit() {
    setIsModalOpen(false);
    onSubmit(true, true);
  }
  // If open state is set to false don't display modal
  return !isModalOpen ? (
    <div></div>
  ) : (
    <>
      <div
        className="fixed top-0 left-0 right-0 bottom-0 bg-black opacity-70 z-40"
        onClick={() => setIsModalOpen(false)}
      />
      <div className="fixed top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 sm:px-8 px-4 sm:pb-6 pb-3 sm:pt-6 pt-3 z-50 border bg-white lg:w-1/2 w-10/12">
        <h3 className="flex items-center justify-center pb-6 text-blue-600 text-base sm:text-lg">
          <b>Issue with your property</b>
        </h3>
        <p className="pb-6 text-sm sm:text-base text-gray-500">
          {errorMessage}
        </p>
        <div className="flex float-right pr-4">
          <button
            className="text-red-500 pr-10 cursor-pointer"
            onClick={() => setIsModalOpen(false)}>
            CANCEL
          </button>
          <button
            className="text-green-500 cursor-pointer"
            onClick={() => handleSubmit()}>
            {!isLoading ? "CONTINUE" : <DotSpinner />}
          </button>
        </div>
      </div>
    </>
  );
};

export default ErrorModal;
