function Nav() {
  return (
    <div className="w-full h-24 md:h-24 bg-white flex flex-col items-center border-b border-gray-100 shadow">
      <div className="h-full w-11/12 md:w-8/12 flex flex-row items-center justify-center sm:justify-start">
        <img src="tcc-logo.png" alt="Tauranga City Council logo" className="h-16 md:h-20" />
      </div>
    </div>
  );
};

export default Nav;
